import Controller from '@ember/controller';
import { action, set, get } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task, all } from 'ember-concurrency';
import { alias, filter, filterBy } from 'macro-decorators';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';
import _isEqual from 'lodash/isEqual';
import { createDeskStorePayload } from 'garaje/utils/bulk-map-operations/payload-utils';
import createAssignmentStorePayload from 'garaje/utils/bulk-map-operations/create-assignments';
import { GENERIC_RESOURCE_TYPES } from 'garaje/utils/enums';

export default class MapsEditShowController extends Controller {
  @service flashMessages;
  @service featureFlags;
  @service featureConfig;
  @service store;
  @service state;
  @service router;
  @service ajax;
  @service metrics;
  @service abilities;
  @service resourceOverview;
  @service mapVersions;
  @service statsig;

  @alias('state.currentLocation') currentLocation;
  // TODO: need hasMaxActiveDesks

  @filterBy('mapFeatures', 'hasDirtyAttributes') dirtyFeatures;
  @filter('mapFeatures', (feature) => feature.hasError || !feature.name) errorFeatures;
  @filterBy('desks', 'isDeleted', false) useableDesks = [];
  @filterBy('desks', 'hasDirtyAttributes') desksWithDirtyAttributes;
  @filter('neighborhoods', (neighborhood) => {
    return neighborhood.hasDirtyAttributes && Object.keys(neighborhood.changedAttributes()).includes('displayColor');
  })
  dirtyNeighborhoods;

  @tracked employees = [];
  @tracked rooms = [];
  @tracked deliveryAreas = [];
  @tracked desks = [];
  @tracked amenities = [];
  @tracked neighborhoods = [];
  @tracked resourceInsights = [];

  @tracked employeesPage = 0;
  @tracked employeesCount = 0;
  @tracked limit = 25;
  @tracked selectedFeature;
  @tracked mapFeatures = [];
  @tracked deletableFeatures = [];
  @tracked paymentSources = [];
  @tracked shouldResetMap = false;

  @tracked leafletMap = null;
  @tracked drawingArea = null;

  @tracked gqlMapFeatures = {
    desks: {},
    rooms: {},
    'points-of-interest': {},
  };
  @tracked gqlEmployees = {
    unassigned: [],
    assigned: [],
  };
  @tracked selectedPanelView = '';
  @tracked selectedNodeId = null;
  @tracked selectedEmployeeEmail = null;
  @tracked selectedAssignmentId = null;
  @tracked shouldShowEmployeeForm = false;
  @tracked expandedMap = [
    'resources/desks',
    'resources/rooms',
    'resources/points-of-interest',
    'seating/assigned',
    'seating/unassigned',
  ];

  get saveDisabled() {
    return (
      !(
        isPresent(this.dirtyDesks) ||
        isPresent(this.dirtyFeatures) ||
        isPresent(this.deletableFeatures) ||
        isPresent(this.dirtyNeighborhoods)
      ) || isPresent(this.errorFeatures)
    );
  }

  get dirtyDesks() {
    return this.desks.filter((desk) => {
      return (desk.hasDirtyAttributes || desk.hasDirtyRelationships) && this.canEditDesk(desk);
    });
  }

  @action
  canEditDesk(desk) {
    if (this.abilities.can('edit maps')) {
      return true;
    }

    const neighborhoodId = desk.belongsTo('neighborhood').id();

    if (!neighborhoodId) {
      return false;
    }

    const mapAbility = this.abilities.abilityFor('map');

    if (mapAbility?.editableNeighborhoodIds.includes(neighborhoodId)) {
      return true;
    }

    return false;
  }

  @action
  resetData() {
    this.employees = [];
    this.employeesPage = 0;
    this.employeesCount = 0;
    this.selectedFeature = null;

    this.selectedNodeId = null;
    this.selectedEmployeeEmail = null;
    this.selectedAssignmentId = null;
    window.removeEventListener('keydown', this.keyDownHandler);
  }

  @action
  setEmployeeFormVisibility(shouldShowEmployeeForm) {
    if (!shouldShowEmployeeForm) {
      this.selectedEmployeeEmail = null;
      this.selectedAssignmentId = null;
    }
    return (this.shouldShowEmployeeForm = shouldShowEmployeeForm);
  }

  hasDirtyAssignments() {
    return this.store.peekAll('assignment').some((it) => it.hasDirtyAttributes);
  }

  clearDirtyAssignments() {
    this.store
      .peekAll('assignment')
      .filter((it) => it.hasDirtyAttributes)
      .forEach((dirtyAssignment) => dirtyAssignment.rollbackAttributes());
  }

  _setNumberOfDesksInNeighborhoods() {
    const neighborhoodMap = {};

    this.desks.forEach((desk) => {
      const neighborhoodName = desk?.neighborhoodName;

      if (neighborhoodName) {
        if (neighborhoodMap[neighborhoodName]) {
          neighborhoodMap[neighborhoodName] += 1;
        } else {
          neighborhoodMap[neighborhoodName] = 1;
        }
      }
    });

    this.neighborhoods.forEach((neighborhood) => {
      if (neighborhoodMap[neighborhood.name]) {
        set(neighborhood, 'numberOfDesks', neighborhoodMap[neighborhood.name]);
      } else {
        set(neighborhood, 'numberOfDesks', 0);
      }
    });
  }

  loadResoucesDataTask = task({ drop: true }, async () => {
    const currentLocationId = this.currentLocation.id;
    [this.deliveryAreas, this.desks, this.neighborhoods, this.amenities, this.resourceInsights] = await all([
      this.store.query('delivery-area', { filter: { location: currentLocationId } }),
      this.store
        .query('desk', {
          filter: {
            'location-id': currentLocationId,
            'floor-id': get(this.model.mapFloor, 'id'),
            'include-draft-desks': true,
          },
          include: 'amenities',
        })
        .then((desks) => desks.toArray()),
      this.store
        .query('neighborhood', {
          filter: { 'location-id': currentLocationId },
          meta: 'desk-count,desk-count-by-floor',
        })
        .then((neighborhoods) => neighborhoods.toArray()),
      this.store.query('amenity', { filter: { 'location-id': currentLocationId } }),
      this.resourceOverview.getResourceInsightsTask.perform(this.model.areaMap.id, this.model.mapFloor.id),
      this.loadEmployeesTask.perform(),
      this.loadRoomsTask.perform(),
    ]);
    if (this.featureFlags.isEnabled('map-version-history')) {
      this.latestMapVersionId = await this.store
        .query('map-version', {
          filter: { 'area-map': this.model.areaMap.id },
          page: {
            size: 1,
          },
        })
        .then((mapVersions) => mapVersions.toArray()[0]?.id);
    }
    await this.loadAssignedAndScheduledEmployeesTask.perform();
    this._setNumberOfDesksInNeighborhoods();
  });

  get employeeQuery() {
    const { limit, employeesPage } = this;
    const offset = employeesPage * limit;
    return {
      page: { limit, offset },
      filter: {
        locations: this.currentLocation.id,
        deleted: false,
      },
      include: 'assistants',
      sort: 'name',
    };
  }

  get hasMoreEmployeePages() {
    return this.employeesPage * this.limit < this.employeesCount;
  }

  loadRoomsTask = task({ drop: true }, async () => {
    const roomsInLocation = await this.ajax.request(urlBuilder.roomba.getAvailableRooms(this.currentLocation.id), {
      headers: { accept: 'application/vnd.api+json' },
      contentType: 'application/vnd.api+json',
    });

    // Push rooms to store
    roomsInLocation.data.map((room) => {
      return this.store.push(this.store.normalize('room', room));
    });

    this.rooms = this.store.peekAll('room', { filter: { location: this.currentLocation.id } });
  });

  loadEmployeesTask = task({ enqueue: true }, async () => {
    const employees = await this.store.query('employee', this.employeeQuery);
    this.employeesCount = employees.meta.total;
    this.employees.pushObjects(employees.toArray());
    this.employees = this.employees.filter((el) => el !== 'loadMore').uniqBy('email');
    this.employeesPage++;

    if (this.hasMoreEmployeePages) {
      this.employees.pushObject('loadMore');
    }
  });

  loadMoreEmployeesTask = task({ drop: true }, async () => {
    if (!this.hasMoreEmployeePages) return;
    try {
      await this.loadEmployeesTask.perform();
    } catch (e) {
      this.employeesPage--;
    }
  });

  loadAssignedAndScheduledEmployeesTask = task({ restartable: true }, async () => {
    const employeesPromises = [];
    let employeeResponses = [];
    const emailLimit = 50;
    const assignedToEmails = this.model.desksInLocation
      .filter((desk) => desk.assignedTo)
      .map((desk) => desk.assignedTo);
    const scheduledEmployeeIds = await Promise.all(
      this.model.desksInLocation.map(async (desk) => {
        const assignments = await desk.assignments;
        return assignments.map((assignment) => assignment.belongsTo('employee')?.id()).filter(Boolean);
      }),
    ).then((results) => {
      return [...new Set(results.flat())];
    });

    // TODO: paginate this request
    let scheduledEmployeesPromise = null;
    if (scheduledEmployeeIds.length > 0) {
      scheduledEmployeesPromise = this.store.query('employee', {
        filter: {
          id: scheduledEmployeeIds.join(','),
          deleted: false,
        },
        include: 'user',
      });
    }
    const userEmails = assignedToEmails;
    let offset = 0;
    let page = 0;
    while (offset < userEmails.length) {
      const userEmailsPaginated = userEmails.slice(offset, offset + emailLimit);
      const fetchedEmployeesPromise = this.store.query('employee', {
        filter: {
          locations: this.state.currentLocation.id,
          'email-in': userEmailsPaginated.join(','),
          deleted: false,
        },
        include: 'user',
        page: { limit: 100, offset: 0 },
      });
      page++;
      offset = page * emailLimit;
      employeesPromises.push(fetchedEmployeesPromise);
    }
    if (scheduledEmployeesPromise !== null) {
      employeesPromises.push(scheduledEmployeesPromise);
    }
    employeeResponses = await all(employeesPromises);
    const employees = [];
    employeeResponses.forEach((response) => response.forEach((employee) => employees.push(employee)));
    this.employees.pushObjects(employees);
    this.employees = this.employees.filter((el) => el !== 'loadMore').uniqBy('email');
    if (this.hasMoreEmployeePages) {
      this.employees.pushObject('loadMore');
    }
  });

  get sortedMapFloors() {
    const mapFloors = [...this.model.areaMap.mapFloors.sortBy('ordinality')];
    if (this.abilities.can('manage-floor maps')) {
      mapFloors.push('create');
    }
    return mapFloors;
  }

  get hasMapFloorWithRasterImage() {
    return this.sortedMapFloors.some((floor) => floor.rasterImageUrl);
  }

  trackSaveEventPayload() {
    const saveEventObject = {
      deleted: { features: {} },
      created: { features: {} },
      updated: { features: {} },
    };

    const getFeatureObj = ({ name, geometry, enabled }) => {
      return {
        name,
        geometry,
        enabled,
      };
    };

    this.deletableFeatures.forEach((feature) => {
      if (saveEventObject.deleted.features[feature.type]) {
        saveEventObject.deleted.features[feature.type].push(feature.id);
      } else {
        saveEventObject.deleted.features[feature.type] = [feature.id];
      }
    });
    const deletedDeskIds = this.dirtyDesks.filter((desk) => desk.isDeleted).map((desk) => desk.id);
    saveEventObject.deleted['desks'] = deletedDeskIds;

    const newFeatures = this.dirtyFeatures.filterBy('isNew');
    newFeatures.forEach((feature) => {
      const featureObj = getFeatureObj(feature);
      if (saveEventObject.created.features[feature.type]) {
        saveEventObject.created.features[feature.type].push(featureObj);
      } else {
        saveEventObject.created.features[feature.type] = [featureObj];
      }
    });

    const newDesks = this.dirtyDesks.filterBy('isNew');
    saveEventObject.created['desks'] = newDesks.map(({ name, neighborhood, assignedTo, enabled, xPos, yPos }) => ({
      name,
      neighborhood,
      assignedTo,
      enabled,
      xPos,
      yPos,
    }));

    const getUpdatedAttributes = (record) => {
      const updatedObj = { id: record.id };
      const changed = record.changedAttributes();
      for (const attr in changed) {
        updatedObj[attr] = changed[attr][1];
      }
      return updatedObj;
    };

    const updatedFeatures = this.dirtyFeatures.filter((feature) => !feature.isNew && !feature.isDeleted);
    updatedFeatures.forEach((feature) => {
      const featureObj = getUpdatedAttributes(feature);
      if (saveEventObject.updated.features[feature.type]) {
        saveEventObject.updated.features[feature.type].push(featureObj);
      } else {
        saveEventObject.updated.features[feature.type] = [featureObj];
      }
    });

    const updatedDesks = this.dirtyDesks.filter((desk) => !desk.isNew && !desk.isDeleted);
    saveEventObject.updated['desks'] = updatedDesks.map((desk) => getUpdatedAttributes(desk));

    this.dirtyNeighborhoods.forEach((neighborhood) => {
      const neighborhoodObj = getUpdatedAttributes(neighborhood);
      if (saveEventObject.updated['neighborhoods']) {
        saveEventObject.updated['neighborhoods'].push(neighborhoodObj);
      } else {
        saveEventObject.updated['neighborhoods'] = [neighborhoodObj];
      }
    });

    return saveEventObject;
  }

  saveTask = task({ drop: true }, async () => {
    const saveEventPayload = this.trackSaveEventPayload();
    try {
      const shouldSetDeskLocations = this.dirtyDesks.length || this.desks.filterBy('isDeleted').length;

      if (this.featureFlags.isEnabled('bulk-map-operations')) {
        // When creating or deleting desks the backend will create/delete the associated feature
        // This is why mapFeatures are fetched after the desks are created/deleted
        await this.deleteDesksTask.perform();
        // to avoid name conflict issues in the backend, we need to delete desks first
        await all([this.createDesksTask.perform(), this.updateDesksTask.perform()]);
        this.desks = this.store
          .peekAll('desk')
          .toArray()
          .filter((desk) => desk.belongsTo('floor').id() === this.model.mapFloor.id);
      } else {
        await all(
          this.deletableFeatures
            .filter((feature) => !['desk', ...GENERIC_RESOURCE_TYPES].includes(feature.type))
            .invoke('destroyRecord'),
        );
        await all(this.desks.filterBy('isDeleted').rejectBy('isDestroyed').invoke('save'));
        await all(this.dirtyDesks.invoke('save'));
      }

      if (this.featureFlags.isEnabled('web-parking-resource')) {
        await this.postGenericsTask.perform();
      }

      if (this.featureFlags.isEnabled('bulk-map-operations')) {
        await this.deleteFeaturesTask.perform();
        await all([this.createFeaturesTask.perform(), this.updateFeaturesTask.perform()]);
      } else {
        const saveableFeatures = this.dirtyFeatures.filter(
          (feature) => !['desk', ...GENERIC_RESOURCE_TYPES].includes(feature.type),
        );
        // When a new desk is saved the feature is automatically created on the backend.
        // When an existing feature is sent in a POST request the backend will return the existing feature
        // if an externalId is provide. If there is no externalId the backend error. Even though the feature is
        // already created we send a POST request to update the isNew to false in ember data
        const newDeskFeatures = this.dirtyFeatures.filter((feature) => feature.isNew && feature.type === 'desk');
        newDeskFeatures.forEach((feature) => {
          set(feature, 'externalId', feature.desk.id);
        });
        const saveableDeskFeatures = this.dirtyFeatures.filter(
          (feature) => feature.type === 'desk' && feature.externalId,
        );

        await all(saveableFeatures.invoke('save'), saveableDeskFeatures.invoke('save'));
      }

      const promiseArray = [];
      promiseArray.push(
        this.store.query('map-feature', {
          filter: {
            'area-map': this.model.areaMap.id,
          },
        }),
      );

      if (shouldSetDeskLocations) {
        promiseArray.push(this.state.setDeskLocationsTask.perform());
      }

      promiseArray.push(this.dirtyNeighborhoods.invoke('save'));

      const [mapFeatures, _setDeskLocations, _neighborhoods] = await all(promiseArray);

      // assignment creation on the server is handled in the PATCH /desk request
      // we need to unload the dirty assignments, as these are dupes at this point
      this.store
        .peekAll('assignment')
        .filterBy('hasDirtyAttributes')
        .forEach((it) => {
          this.store.unloadRecord(it);
        });

      this.mapFeatures = mapFeatures.toArray();
      this.deletableFeatures = [];
      this.shouldResetMap = true;

      this.selectedFeature = null;
      this.selectedNodeId = null;
      this.selectedEmployeeEmail = null;
      this.selectedAssignmentId = null;
      this.setEmployeeFormVisibility(false);

      if (this.featureFlags.isEnabled('map-version-history')) {
        await this.mapVersions.snapshotTask.perform('map-publish', this.model.areaMap);
      }

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }

    this.metrics.trackEvent('[dashboard] Map - Saved', saveEventPayload);
  });

  updateDesksTask = task({ drop: true }, async () => {
    if (this.updateDesksPayload.length === 0) {
      return;
    }
    const includeAssignments = !!this.dirtyDesks.any((desk) => {
      return desk.assignmentDetails?.length > 0;
    });
    const url = urlBuilder.rms.bulkDesksUpdateEndpoint(includeAssignments);
    const response = await this.ajax.request(url, {
      accept: 'application/vnd.api+json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({ 'atomic:operations': this.updateDesksPayload }),
    });
    response.data.forEach((desk) => {
      this.store.pushPayload('desk', createDeskStorePayload(desk));
    });
    if (response.included?.length > 0) {
      response.included.forEach((item) => {
        if (item.type === 'assignments') {
          // convert to `desks` since we don't support generics yet
          item.relationships['desk'] = item.relationships.resource;
          item.relationships.desk.data.type = 'desks';
          delete item.relationships.resource;
          this.store.pushPayload('assignment', createAssignmentStorePayload(item));
        }
      });
    }
  });

  generateDeskPayload(desk) {
    const neighborhoodId = desk?.belongsTo('neighborhood')?.id();

    const payload = {
      data: {
        type: 'resource',
        attributes: {
          name: desk.name,
          'neighborhood-id': neighborhoodId,
          enabled: desk.enabled,
          'assigned-to': desk.assignedTo,
        },
        relationships: {
          amenities: {
            data: desk
              .hasMany('amenities')
              .ids()
              .map((amenityId) => ({
                type: 'amenities',
                id: amenityId,
              })),
          },
          floor: {
            data: {
              type: 'floors',
              id: desk.floorId ?? desk?.belongsTo('floor')?.id(),
            },
          },
          location: {
            data: {
              type: 'locations',
              id: this.currentLocation.id,
            },
          },
          neighborhood: {
            data: neighborhoodId
              ? {
                  type: 'neighborhoods',
                  id: neighborhoodId,
                }
              : null,
          },
        },
      },
    };

    if (desk.xPos != null && desk.yPos != null) {
      payload.data.attributes.geometry = {
        type: 'Point',
        coordinates: [desk.xPos, desk.yPos],
      };
    }

    if (desk.assignmentDetails?.length > 0) {
      payload.data.attributes.assignments = desk.assignmentDetails.map((assignment) => ({
        'employee-id': assignment['employee-id'],
        'start-time': assignment['start-time'],
        'scheduled-by': assignment['scheduled-by'],
        'should-send-notification': assignment['should-send-notification'],
      }));
    }

    return payload;
  }

  get updateDesksPayload() {
    return this.dirtyDesks
      .filter((desk) => !desk.isNew && !desk.isDeleted)
      .map((desk) => {
        const payload = this.generateDeskPayload(desk);
        payload.op = 'update';
        payload.data.id = desk.id;
        return payload;
      });
  }

  get createDesksPayload() {
    return this.dirtyDesks
      .filter((desk) => desk.isNew)
      .map((desk) => {
        const payload = this.generateDeskPayload(desk);
        payload.op = 'add';
        payload.data.attributes['resource-type-id'] = 1;
        return payload;
      });
  }

  createDesksTask = task({ drop: true }, async () => {
    if (this.createDesksPayload.length === 0) {
      return;
    }
    const includeAssignments = !!this.dirtyDesks.any((desk) => {
      return desk.assignmentDetails?.length > 0;
    });
    const url = urlBuilder.rms.bulkDesksCreateEndpoint(includeAssignments);
    // attempt to create multiple desks via the bulk-desk-creation endpoint
    const response = await this.ajax.request(url, {
      accept: 'application/vnd.api+json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({ 'atomic:operations': this.createDesksPayload }),
    });
    this.selectedFeature = null;
    this.dirtyDesks
      .filter((desk) => desk.isNew)
      .forEach((dirtyDesk) => {
        dirtyDesk.unloadRecord();
      });
    response.data.forEach((desk) => {
      this.store.pushPayload('desk', createDeskStorePayload(desk));
    });
    if (response.included?.length > 0) {
      response.included.forEach((item) => {
        if (item.type === 'assignments') {
          // convert to `desks` since we don't support generics yet
          item.relationships['desk'] = item.relationships.resource;
          item.relationships.desk.data.type = 'desks';
          delete item.relationships.resource;
          this.store.pushPayload('assignment', createAssignmentStorePayload(item));
        }
      });
    }
  });

  get deleteDesksPayload() {
    return this.desks
      .filter((desk) => desk.isDeleted && !desk.isNew)
      .map((desk) => {
        return {
          op: 'remove',
          data: {
            id: desk.id,
          },
        };
      });
  }

  deleteDesksTask = task({ drop: true }, async () => {
    if (this.deleteDesksPayload.length === 0) {
      return;
    }
    const url = urlBuilder.rms.bulkDesksDeleteEndpoint();

    await this.ajax
      .request(url, {
        accept: 'application/vnd.api+json',
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({ 'atomic:operations': this.deleteDesksPayload }),
      })
      .then(() => {
        this.desks
          .filter((desk) => desk.isDeleted)
          .forEach((deletedDesk) => {
            deletedDesk.unloadRecord();
          });
      });
  });

  get createNonDeskFeaturesPayload() {
    // Desks are filtered from the payload because the backend will manage feature creation when new desks are saved
    return this.dirtyFeatures
      .filter((feature) => feature.isNew && feature.type !== 'desk')
      .map((feature) => {
        // If the feature type is a polygon, we need to close the linestring
        const geometryType = feature.geometry.type;
        let coordinates = feature.geometry.coordinates;
        const { length, 0: start, [length - 1]: end } = coordinates;

        if (geometryType === 'Polygon' && !_isEqual(start, end)) {
          coordinates = [...coordinates, start];
        }
        return {
          op: 'add',
          data: {
            type: 'features',
            attributes: {
              geometry: {
                coordinates: coordinates,
                type: geometryType,
              },
              enabled: feature.enabled,
              type: feature.type.toUpperCase().replace(/-/g, '_'),
              externalId: feature.externalId,
              notes: feature.notes,
              name: feature.name,
            },
            relationships: {
              'map-floor': {
                data: {
                  type: 'map-floors',
                  id: feature.belongsTo('mapFloor').id(),
                },
              },
              'area-map': {
                data: {
                  type: 'area-maps',
                  id: feature.belongsTo('areaMap').id(),
                },
              },
            },
          },
        };
      });
  }

  createFeaturesTask = task({ drop: true }, async () => {
    if (this.createNonDeskFeaturesPayload.length === 0) {
      return;
    }
    const url = urlBuilder.maps.bulkFeaturesCreateEndpoint();
    await this.ajax.request(url, {
      accept: 'application/vnd.api+json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({ 'atomic:operations': this.createNonDeskFeaturesPayload }),
    });

    this.dirtyFeatures
      .filter((feature) => feature.isNew)
      .forEach((dirtyFeature) => {
        dirtyFeature.unloadRecord();
      });
  });

  get updateNonDeskFeaturesPayload() {
    return this.dirtyFeatures
      .filter((feature) => !feature.isNew && !feature.isDeleted && feature.type !== 'desk')
      .map((feature) => {
        // If the feature type is a polygon, we need to close the linestring
        const geometryType = feature.geometry.type;
        let coordinates = feature.geometry.coordinates;
        const { length, 0: start, [length - 1]: end } = coordinates;

        if (geometryType === 'Polygon' && !_isEqual(start, end)) {
          coordinates = [...coordinates, start];
        }

        return {
          op: 'update',
          data: {
            type: 'features',
            id: feature.id,
            attributes: {
              geometry: {
                coordinates: coordinates,
                type: geometryType,
              },
              enabled: feature.enabled,
              type: feature.type.toUpperCase().replace(/-/g, '_'),
              externalId: feature.externalId,
              notes: feature.notes,
              name: feature.name,
            },
          },
        };
      });
  }

  updateFeaturesTask = task({ drop: true }, async () => {
    if (this.updateNonDeskFeaturesPayload.length === 0) {
      return;
    }
    const url = urlBuilder.maps.bulkFeaturesUpdateEndpoint();
    await this.ajax.request(url, {
      accept: 'application/vnd.api+json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({ 'atomic:operations': this.updateNonDeskFeaturesPayload }),
    });
  });

  get deleteNonDeskFeaturesPayload() {
    // Desks are filtered from the payload because the backend will manage feature deletion when new desks are saved
    return this.deletableFeatures
      .filter((feature) => feature.type !== 'desk')
      .map((feature) => {
        return {
          op: 'remove',
          data: {
            type: 'features',
            id: feature.id,
          },
        };
      });
  }

  deleteFeaturesTask = task({ drop: true }, async () => {
    if (this.deleteNonDeskFeaturesPayload.length === 0) {
      return;
    }
    const url = urlBuilder.maps.bulkFeaturesDeleteEndpoint();
    await this.ajax.request(url, {
      accept: 'application/vnd.api+json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({ 'atomic:operations': this.deleteNonDeskFeaturesPayload }),
    });

    this.dirtyFeatures
      .filter((feature) => feature.isDeleted)
      .forEach((dirtyFeature) => {
        dirtyFeature.unloadRecord();
      });
  });

  getFeatureOp = (feature) => {
    if (feature.isDeleted) {
      return 'DELETE';
    }

    if (feature.isNew) {
      return 'ADD';
    }

    return 'UPDATE';
  };

  postGenericsTask = task({ drop: true }, async () => {
    const deletedResources = this.deletableFeatures.filter((feature) => GENERIC_RESOURCE_TYPES.includes(feature.type));
    const modifiedResources = this.dirtyFeatures.filter((feature) => GENERIC_RESOURCE_TYPES.includes(feature.type));

    deletedResources.forEach((feature) => feature.deleteRecord());

    const resources = [...deletedResources, ...modifiedResources];

    if (!resources.length) {
      return;
    }

    const genericsPayload = resources.map((feature) => ({
      op: this.getFeatureOp(feature),
      data: {
        id: feature.externalId,
        name: feature.name,
        geometry: feature.geometry,
        resourceTypeId: 2,
        amenities: [],
        assignments: [],
        floorId: parseInt(feature.belongsTo('mapFloor').id()),
        relationId: `urn:envoy:location:${this.state.currentLocation.id}`,
      },
    }));

    const url = urlBuilder.rms.bulkResourcesUpdate();

    await this.ajax.request(url, {
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({ data: genericsPayload }),
    });

    resources
      .filter((resource) => resource.isNew || resource.isDeleted)
      .forEach((resource) => {
        resource.unloadRecord();
      });
  });
}
