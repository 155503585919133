import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { formatInTimeZone } from 'date-fns-tz';
import type AbilitiesService from 'ember-can/services/abilities';
import { task, dropTask } from 'ember-concurrency';
import type FlowModel from 'garaje/models/flow';
import type GroupInviteModel from 'garaje/models/group-invite';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { DATE_FNS_YYYY_MM_DD } from 'garaje/utils/date-fns-tz-utilities';
import { NON_ASSIGNABLE_FLOWS } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import type { RecordArray } from 'garaje/utils/type-utils';
import { filter, sortBy } from 'macro-decorators';
import { defer } from 'rsvp';

import type { VisitorsInvitesIndexRouteModelParams } from '../../index/route';

import type { VisitorsGroupInvitesShowRouteModel } from './route';

const NON_ASSIGNABLE_FLOW_STRINGS = NON_ASSIGNABLE_FLOWS.map((f): string => `${f}`);

export default class VisitorsGroupInvitesShowController extends Controller {
  declare model: VisitorsGroupInvitesShowRouteModel;

  @service declare abilities: AbilitiesService;
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare router: RouterService;

  @tracked flows: FlowModel[] = [];

  @filter('flows', (flow: FlowModel) => !NON_ASSIGNABLE_FLOW_STRINGS.includes(flow.type) && !flow.employeeCentric)
  flowOptionsInit!: RecordArray<FlowModel>;
  @sortBy('flowOptionsInit', 'position') flowOptions!: RecordArray<FlowModel>;

  // Stub for property support
  get locationIsConnectedToProperty(): boolean {
    // return this.model.connectedTenants?.length > 0;
    return false;
  }

  get canSelectMultipleInvites(): boolean {
    return (
      this.abilities.can('view all invites') ||
      this.abilities.can('edit invites') ||
      this.abilities.can('delete invites')
    );
  }

  get canDelete(): boolean {
    return this.abilities.can('delete invite', this.model.groupInvite);
  }

  // Remove related invites from the store to avoid
  // "Passed unknown unsubscribe token to unsubscribe" error
  unloadAssociatedInvites(groupInvite: GroupInviteModel): void {
    const { store } = this;
    const loadedInvites = store
      .peekAll('invite')
      .filter((i: InviteModel) => i.groupInviteId && i.groupInviteId === groupInvite.id);

    loadedInvites.forEach((i: InviteModel) => store.unloadRecord(i));
  }

  loadAdditionalDataTask = task(
    async (location: LocationModel): Promise<{ flows: FlowModel[]; location: LocationModel }> => {
      // The type definition in state.d.ts and the JS for loadFlows in user-state.js disagree about return type
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const flows = await this.state.loadFlows({ reload: false, locationId: location.id });

      this.flows = flows;

      return { flows, location };
    },
  );

  deleteGroupInviteTask = task(async (): Promise<void> => {
    const {
      model: { groupInvite },
      flashMessages,
    } = this;
    const {
      expectedArrivalTime,
      location: { timezone },
    } = groupInvite;
    const queryParams: Partial<VisitorsInvitesIndexRouteModelParams> = {};
    let confirmedNotifyCancel: unknown = false;
    let cancellationPayload: unknown = undefined;

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/await-thenable -- encapsulated tasks do not have great TS support
    [confirmedNotifyCancel, cancellationPayload] = await this.confirmGroupInviteDeletionTask.perform();

    if (!confirmedNotifyCancel) return;

    try {
      queryParams.date = formatInTimeZone(expectedArrivalTime, timezone, DATE_FNS_YYYY_MM_DD);

      this.unloadAssociatedInvites(groupInvite);

      await groupInvite.destroyRecord();

      // If sending a custom message about cancellation, trigger notify
      if (cancellationPayload) {
        await groupInvite.notifyGroupInvite({ ...cancellationPayload });
      }

      flashMessages.showAndHideFlash('success', `Deleted "${groupInvite.groupName}"`);

      this.router.transitionTo('visitors.invites.index', { queryParams });
    } catch (err) {
      const error = parseErrorForDisplay(err) || 'Unknown error. Saving failed.';

      flashMessages.showFlash('error', error);
    }
  });

  @dropTask
  confirmGroupInviteDeletionTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: {
      context: VisitorsGroupInvitesShowController;
      abort?: () => void;
      continue?: (payload: unknown) => void;
    }) {
      const deferred = defer();

      this.abort = () => deferred.resolve([false]);
      this.continue = (payload?: unknown) => deferred.resolve([true, payload]);

      return yield deferred.promise;
    },
  };
}
