import type ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type AcsLocationModel from 'garaje/models/acs-location';
import type PluginModel from 'garaje/models/plugin';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type StateService from 'garaje/services/state';
import urlBuilder from 'garaje/utils/url-builder';
import { hash } from 'rsvp';

import type AppsSettingsController from './controller';

type DiplomatClient = {
  id: string;
  secret: string;
  location_id: number;
  enabled: boolean;
  plugin_install_id?: number;
  plugin_id: string;
  internal_url: string;
  connected: boolean;
  created_at: string;
  updated_at: string;
};

export interface AppsSettingsRouteModel {
  notificationSetting: AcsLocationModel | null;
  pluginInstalls: ArrayProxy<PluginInstallModel>;
  plugins: ArrayProxy<PluginModel>;
  diplomatClients: Array<DiplomatClient>;
}

type DiplomatClientResponse = { clients: Array<DiplomatClient> };

export default class AppsSettingsRoute extends Route {
  @service declare state: StateService;
  @service declare store: Store;
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('visit workplace')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<AppsSettingsRouteModel> {
    const locationId = this.state.currentLocation.id;
    const plugins = this.store.query('plugin', {
      filter: { location: locationId },
    });
    const pluginInstalls = this.store.query('plugin-install', {
      filter: { location: locationId },
      page: { limit: 100 },
    });

    const url = urlBuilder.diplomatServer.getDiplomatClientsUrl(this.state.currentLocation.id);
    let diplomatClients: Array<DiplomatClient> = [];
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
      credentials: 'include',
    });
    if (response.ok) {
      const { clients } = <DiplomatClientResponse>await response.json();
      diplomatClients = clients;
    } else {
      // eslint-disable-next-line no-console
      console.error(`Error fetching diplomat clients.`);
    }

    return hash({
      notificationSetting: null,
      pluginInstalls,
      plugins,
      currentLocation: this.state.currentLocation,
      diplomatClients,
    });
  }

  setupController(controller: AppsSettingsController, model: AppsSettingsRouteModel, transition: Transition): void {
    super.setupController(controller, model, transition);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    void controller.loadNotificationSettings.perform();
  }

  @action
  refreshModel(): void {
    this.refresh();
  }
}
