import { action } from '@ember/object';
import { service } from '@ember/service';
import type Model from '@ember-data/model';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { DiplomatAblePluginOption } from 'garaje/utils/binary-download-options';
import { DIPLOMAT_ABLE_PLUGIN_OPTIONS } from 'garaje/utils/binary-download-options';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';

interface DiplomatConfigEditDiplomatClientComponentArgs {
  selectedClient: ClientData;
  onClose?: () => unknown;
  onUpdateClient: () => Promise<void>;
  currentLocation: Model;
}

interface ClientData {
  plugin_name: string;
  plugin_id: string;
  plugin_install_id?: number;
  id: string;
  secret: string;
  enabled: boolean;
  connection_status: boolean;
  internal_url: string;
}

export default class DiplomatConfigEditDiplomatClientComponent extends Component<DiplomatConfigEditDiplomatClientComponentArgs> {
  @service declare flashMessages: FlashMessagesService;

  @tracked diplomatEnabledPluginsOptions: DiplomatAblePluginOption[] = [];
  @tracked selectedIntegration?: DiplomatAblePluginOption;
  @tracked clientId?: string;
  @tracked enabled?: boolean;
  @tracked internalUrl?: string;

  diplomatAblePluginOptions: DiplomatAblePluginOption[] = DIPLOMAT_ABLE_PLUGIN_OPTIONS;

  get disabled(): boolean {
    const { id, plugin_id, enabled, internal_url } = this.args.selectedClient;
    const isNotEdited =
      this.clientId === id &&
      this.selectedIntegration?.id === plugin_id &&
      this.enabled === enabled &&
      this.internalUrl === internal_url;
    const isMissingConfiguration =
      this.selectedIntegration === undefined ||
      this.clientId === undefined ||
      this.clientId === '' ||
      this.internalUrl === undefined ||
      this.internalUrl === '';

    return isNotEdited || isMissingConfiguration;
  }

  constructor(owner: unknown, args: DiplomatConfigEditDiplomatClientComponentArgs) {
    super(owner, args);
    const { id, plugin_id, enabled, internal_url } = { ...this.args.selectedClient };
    this.selectedIntegration = DIPLOMAT_ABLE_PLUGIN_OPTIONS.filter((option) => plugin_id === option.id)[0];
    this.clientId = id;
    this.enabled = enabled;
    this.internalUrl = internal_url;
  }

  saveDiplomatClientTask = task(async () => {
    if (this.disabled) return;
    try {
      const url = urlBuilder.diplomatServer.saveDiplomatClientUrl();
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify({
          id: this.clientId,
          location_id: Number.parseInt(this.args.currentLocation.id),
          enabled: this.enabled,
          plugin_id: this.selectedIntegration?.id,
          internal_url: this.internalUrl,
        }),
        credentials: 'include',
      });
      await this.args.onUpdateClient();
      this.onClose();
      this.flashMessages.showAndHideFlash('success', 'Successfully saved.');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  handleEnabledChanged(enabled: boolean): void {
    this.enabled = enabled;
  }

  @action
  onClose(): void {
    this.args.onClose?.();
  }
}
