import type ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Model from '@ember-data/model';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import fetch from 'fetch';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { DiplomatAblePluginOption } from 'garaje/utils/binary-download-options';
import { DIPLOMAT_ABLE_PLUGIN_OPTIONS } from 'garaje/utils/binary-download-options';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';

interface DiplomatConfigCreateDiplomatClientComponentArgs {
  pluginInstalls: ArrayProxy<PluginInstallModel>;
  onClose?: () => unknown;
  onUpdateClient: () => Promise<void>;
  currentLocation: Model;
}

export default class DiplomatConfigCreateDiplomatClientComponent extends Component<DiplomatConfigCreateDiplomatClientComponentArgs> {
  @service declare flashMessages: FlashMessagesService;

  @tracked diplomatEnabledPluginsOptions: DiplomatAblePluginOption[] = [];
  @tracked selectedIntegration?: DiplomatAblePluginOption;
  @tracked clientId?: string;
  @tracked internalUrl?: string;

  diplomatAblePluginOptions: DiplomatAblePluginOption[] = DIPLOMAT_ABLE_PLUGIN_OPTIONS;

  get disabled(): boolean {
    return (
      this.selectedIntegration === undefined ||
      this.clientId === undefined ||
      this.clientId === '' ||
      this.internalUrl === undefined ||
      this.internalUrl === ''
    );
  }

  get diplomatAblePluginInstall(): number | undefined {
    const selectedPluginId = this.selectedIntegration?.id;
    const validPluginInstall = this.args.pluginInstalls.find(
      (pluginInstall: PluginInstallModel) =>
        // eslint-disable-next-line ember/use-ember-get-and-set
        pluginInstall.status === 'active' && pluginInstall.plugin.get('id') === selectedPluginId,
    );

    return validPluginInstall ? Number.parseInt(validPluginInstall.id) : undefined;
  }

  constructor(owner: unknown, args: DiplomatConfigCreateDiplomatClientComponentArgs) {
    super(owner, args);
  }

  createDiplomatClientTask = task(async (clientId: string) => {
    if (this.disabled) return;

    try {
      const url = urlBuilder.diplomatServer.saveDiplomatClientUrl();
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify({
          id: clientId,
          location_id: Number.parseInt(this.args.currentLocation.id),
          enabled: false,
          plugin_id: this.selectedIntegration?.id,
          internal_url: this.internalUrl,
        }),
        credentials: 'include',
      });
      await this.args.onUpdateClient();
      this.onClose();
      this.flashMessages.showAndHideFlash('success', 'Successfully created.');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  setSelectedIntegration(option: DiplomatAblePluginOption): void {
    this.selectedIntegration = option;
  }

  @action
  setClientId(clientId: string): void {
    this.clientId = clientId;
  }

  @action
  setInternalUrl(internalUrl: string): void {
    this.internalUrl = internalUrl;
  }

  @action
  onClose(): void {
    this.args.onClose?.();
  }
}
