/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type ArrayProxy from '@ember/array/proxy';
import type Controller from '@ember/controller';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import { type Task } from 'ember-concurrency';
import type AreaMapModel from 'garaje/models/area-map';
import type DraftModel from 'garaje/models/draft';
import type MapFeatureModel from 'garaje/models/map-feature';
import type MapFloorModel from 'garaje/models/map-floor';
import type CurrentAdminService from 'garaje/services/current-admin';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import { hash } from 'rsvp';

import type { MapsDraftsAreaMapModel } from '../route';

interface MapsDraftsAreaMapShowRouteModel {
  areaMap: AreaMapModel;
  draft: DraftModel | undefined;
  mapFloor: MapFloorModel;
  mapFeatures: ArrayProxy<MapFeatureModel>;
}

interface MapsDraftsAreaMapShowController extends Controller {
  mapFeatures: any;
  deletableFeatures: any;
  selectedFeature: MapFeatureModel | null;
  loadResourcesDataTask: Task<void, []>;
  draftName: string | undefined;
  model: MapsDraftsAreaMapShowRouteModel;
  resetController(): void;
  registerEventListeners(): void;
}

export default class MapsDraftsAreaMapShowRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare metrics: MetricsService;
  @service declare transitionConfirm: TransitionConfirmService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare statsig: StatsigService;

  beforeModel(): void {
    if (this.abilities.cannot('visit drafts for maps')) {
      void this.router.transitionTo('unauthorized');
      return;
    }

    if (!this.currentAdmin?.employee?.id) {
      void this.router.transitionTo('spaces.maps.drafts');
    }
    this.metrics.trackEvent('Accessed draft map');
  }

  model({ floor_id }: { floor_id: number }): Promise<MapsDraftsAreaMapShowRouteModel> | void {
    const mapAbility = this.abilities.abilityFor('map') as { canViewDraft: (draft?: DraftModel) => boolean };

    const { currentLocation } = this.state;
    const { areaMap } = <MapsDraftsAreaMapModel>this.modelFor('spaces.maps.drafts.area-map');
    const desksInLocation = this.store.query('desk', {
      filter: {
        'location-id': currentLocation?.id,
        booked: true,
        permanentlyAssigned: true,
      },
    });
    const draft = this.store.peekAll('draft').find((draft) => areaMap.id == draft.belongsTo('draftAreaMap').id());

    if (!mapAbility.canViewDraft(draft)) {
      return void this.router.transitionTo('unauthorized');
    }

    const mapFloor = this.store.findRecord('map-floor', floor_id);
    const mapFeatures = this.store.query('map-feature', {
      filter: {
        'area-map': areaMap.id,
      },
    });
    return hash({
      areaMap,
      draft,
      mapFloor,
      mapFeatures,
      desksInLocation,
    });
  }

  setupController(
    controller: MapsDraftsAreaMapShowController,
    model: MapsDraftsAreaMapShowRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    controller.mapFeatures = model.mapFeatures.toArray();
    controller.draftName = model.draft?.name;
    void controller.loadResourcesDataTask.perform();
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = <MapsDraftsAreaMapShowController>this.controller;
    const dirtyFeatures = controller.mapFeatures.filterBy('hasDirtyAttributes');
    const deletableFeatures = controller.deletableFeatures;

    if (isEmpty(dirtyFeatures) && isEmpty(deletableFeatures) && !controller.model.draft?.hasDirtyAttributes) {
      controller.resetController();
      return;
    }

    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        controller.resetController();
      },
    });
  }
}
