/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type AreaMapModel from 'garaje/models/area-map';
import type DraftModel from 'garaje/models/draft';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';

import type { MapsDraftsAreaMapModel } from '../route';

export interface MapsDraftsAreaMapIndexRouteModel {
  areaMap: AreaMapModel;
  draft: DraftModel | undefined;
}

export default class MapsDraftsAreaMapIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare currentAdmin: CurrentAdminService;
  beforeModel(): void {
    if (this.abilities.cannot('visit drafts for maps')) {
      void this.router.transitionTo('unauthorized');
      return;
    }

    if (!this.currentAdmin?.employee?.id) {
      void this.router.transitionTo('spaces.maps.drafts');
      return;
    }

    const { areaMap } = <MapsDraftsAreaMapModel>this.modelFor('spaces.maps.drafts.area-map');
    const mapFloorIds: any = areaMap.hasMany('mapFloors').ids();
    if (mapFloorIds.length) {
      void this.router.transitionTo('spaces.maps.drafts.area-map.show', mapFloorIds[0]);
      return;
    }
  }

  model(): MapsDraftsAreaMapIndexRouteModel {
    const { areaMap } = <MapsDraftsAreaMapModel>this.modelFor('spaces.maps.drafts.area-map');
    const draft = this.store.peekAll('draft').find((draft) => areaMap.id == draft.belongsTo('draftAreaMap').id());
    return { areaMap, draft };
  }
}
