import type EmberArray from '@ember/array';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template/-private/handlebars';
import { waitFor } from '@ember/test-waiters';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  addDays,
  addMinutes,
  addMonths,
  addSeconds,
  addYears,
  endOfDay,
  endOfMonth,
  format,
  formatISO,
  fromUnixTime,
  getUnixTime,
  isAfter,
  isPast,
  isSameDay,
  isSameMonth,
  isToday,
  startOfDay,
  startOfMonth,
  subDays,
  subMinutes,
  subMonths,
} from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import type AbilitiesService from 'ember-can/services/abilities';
import type { Task } from 'ember-concurrency';
import { dropTask, task } from 'ember-concurrency';
import type Employee from 'garaje/models/employee';
import type EmployeeLocation from 'garaje/models/employee-location';
import type InviteModel from 'garaje/models/invite';
import type ReservationModel from 'garaje/models/reservation';
import type WorkplaceDayModel from 'garaje/models/workplace-day';
import type AjaxService from 'garaje/services/ajax';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { SECONDS_IN_A_DAY } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { endTimeOptions, getPartialTimes, hourOptions, startTimeOptions } from 'garaje/utils/hour-options';
import type { NonEmptyArray } from 'garaje/utils/type-utils';
import { isNonEmptyArray } from 'garaje/utils/type-utils';
import urlBuilder from 'garaje/utils/url-builder';
import _groupBy from 'lodash/groupBy';
import { localCopy } from 'tracked-toolbox';

interface Option {
  value: string;
  label: string;
}

interface ReservationModalEmployeeDateSelectArgs {
  allDayEnabled: boolean;
  /**
   * Ideally should be named: `isAutoAssignDeskCheckboxChecked`.
   *
   * ### Notes from 2023-07-28
   * * In the context of the Employee Log, false means we don't attempt to assign a desk at all.
   * * In the context of the Desk Reservation tab, false means we will try to show a floor map to pick a desk.
   */
  autoAssignDesksEnabled?: boolean;
  canUsePartialDay?: boolean;
  createReservationTask: Task<unknown, unknown[]>;
  currentReservation: ReservationModel;
  employees: Employee[];
  floorsWithMap: unknown[];
  getSelectedEmployeeTask: Task<unknown, unknown[]>;
  hasMorePages: boolean;
  /**
   * Ideally should be an enum with `parentTab: 'employee-log' | 'desk-reservations'`
   *
   * `isDbeam` true means that we are in the employee-log tab
   */
  isDbeam?: boolean;
  isEditing: boolean;
  loadMoreTask: Task<unknown, unknown[]>;
  multidayEnabled: boolean;
  onAllDayToggleClick: () => void;
  onDateSelect: (date: number | moment.Moment | Date) => void;
  onMultidayToggleClick: () => void;
  onPartialTimeChange: (key: 'startTime' | 'endTime', time: string) => void;
  reservationModalTask: Task<unknown, unknown[]>;
  searchTask: Task<Employee[], [string]>;
  selectedDates: Date[];
  selectedEmployee: Employee;
  onEmployeeSelect: (employee: Employee) => void;
  selectedTime: { startTime: string; endTime: string };
  setCurrentPage: (page: string) => void;
  workplaceDays: WorkplaceDayModel[];
}

type EmployeeLogTabReservationValidationContext = ReservationValidationContext & {
  parentTab: 'employee_log';
  isDbeam: true;
};

type DeskTabReservationValidationContext = ReservationValidationContext & {
  parentTab: 'desk_reservations';
  isDbeam: false | undefined;
};

type ReservationValidationContext = Pick<
  ReservationModalEmployeeDateSelectArgs,
  | 'selectedEmployee'
  | 'selectedDates'
  | 'multidayEnabled'
  | 'isEditing'
  | 'canUsePartialDay'
  | 'allDayEnabled'
  | 'floorsWithMap'
  | 'autoAssignDesksEnabled'
  | 'isDbeam'
>;

export default class ReservationModalEmployeeDateSelect extends Component<ReservationModalEmployeeDateSelectArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare ajax: AjaxService;

  @localCopy('args.employees') declare loadedEmployees: typeof this.args.employees;
  @localCopy('args.workplaceDays') declare workplaceDays: typeof this.args.workplaceDays;

  @tracked showCalendar = false;
  @tracked showingDate = new Date();
  @tracked inviteForToday: InviteModel | null | undefined;
  @tracked availableDesks = [];
  // @ts-ignore not initialized error
  @tracked employees = this.loadedEmployees;
  @tracked datesToDisplay: (Date | moment.Moment)[] = this.args.selectedDates; // eslint-disable-line ember/no-tracked-properties-from-args
  @tracked deskAvailabilities: { 'start-time'?: string; 'end-time'?: string; availability?: string }[] = [];
  @tracked selectedResourceType = this.resourceTypeOptions[0];

  get currentMonth(): string {
    return format(startOfMonth(this.showingDate), 'yyyy-MM-dd');
  }

  get activeDays(): typeof this.workplaceDays {
    return this.workplaceDays.filter((day) => day.active);
  }

  get selectedForToday(): (typeof this.args.selectedDates)[0] | undefined {
    const { selectedDates } = this.args;
    return selectedDates.find((day) => isToday(subMinutes(day, this.state.minutesBetweenTimezones(day))));
  }

  get selectedDays(): WorkplaceDayModel[] {
    const { selectedDates } = this.args;
    const selectedDays: WorkplaceDayModel[] = [];
    selectedDates.forEach((date) => {
      const currDate = new Date(date);
      const dayOfWeek = format(subMinutes(currDate, this.state.minutesBetweenTimezones(currDate)), 'EEEE');
      const activeDay = this.activeDays.find((day) => day.dayOfWeek === dayOfWeek);
      if (activeDay && !selectedDays.includes(activeDay)) {
        selectedDays.push(activeDay);
      }
    });
    return selectedDays;
  }

  get isInvalidDaySelected(): boolean {
    const { selectedDates } = this.args;
    return selectedDates.some((date) => {
      const currDate = new Date(date);
      const dayOfWeek = format(subMinutes(currDate, this.state.minutesBetweenTimezones(currDate)), 'EEEE');
      const activeDay = this.activeDays.find((day) => day.dayOfWeek === dayOfWeek);
      return !activeDay;
    });
  }

  get latestStartTime(): string {
    const latestStartTime = this.selectedDays.sort((a, b) => (a.startTime.localeCompare(b.startTime) < 0 ? 1 : -1));
    if (this.selectedForToday && latestStartTime.length) {
      const currentTime = format(subMinutes(new Date(), this.state.minutesBetweenTimezones()), 'HH:mm:ss');
      const closestInterval = hourOptions(15, true).find(({ value }) => value.localeCompare(currentTime) >= 0);
      return currentTime.localeCompare(latestStartTime[0]!.startTime) === 1
        ? closestInterval!.value
        : latestStartTime[0]!.startTime;
    }
    return latestStartTime.length ? latestStartTime[0]!.startTime : '00:00:00';
  }

  get earliestEndTime(): string {
    const earliestEndTime = this.selectedDays.sort((a, b) => a.endTime.localeCompare(b.endTime));
    return earliestEndTime.length ? earliestEndTime[0]!.endTime : '23:30:00';
  }

  get startHourOptions(): Option[] {
    const { selectedTime } = this.args;

    const options = startTimeOptions(selectedTime.endTime, 15, true);
    if (this.latestStartTime) {
      return options.filter((option) => {
        return option.value.localeCompare(this.latestStartTime) >= 0;
      });
    }

    return options;
  }

  get endHourOptions(): Option[] {
    const { selectedTime } = this.args;

    const options = endTimeOptions(selectedTime.startTime, 15, true);
    if (!this.earliestEndTime) {
      return options;
    }

    return options.filter((option) => {
      return option.value.localeCompare(this.earliestEndTime) <= 0;
    });
  }

  get daysWithReservations(): { [startDate: string]: ReservationModel[] } {
    return _groupBy(this.reservationsForMonth, (res) => {
      const startDate = fromUnixTime(res.startTime);
      return format(subMinutes(startDate, this.state.minutesBetweenTimezones(startDate)), 'dd-MM-yyyy');
    });
  }

  get daysToCustomize(): {
    date: ReservationModel['startTime'];
    tooltip: SafeString | string;
    class: string;
    disabled: boolean;
  }[] {
    const { currentReservation, canUsePartialDay } = this.args;
    if (!this.reservationsForMonth) {
      return [];
    }
    return Object.keys(this.daysWithReservations).map((date) => {
      // using ! to preserve past behavior
      const reservations = this.daysWithReservations[date]!.sort((a, b) => a.startTime - b.startTime);
      const isCurrentRes = reservations.find((res) => currentReservation?.startTime === res.startTime);
      const bgColor = isCurrentRes ? 'bg-carbon-60 current-res' : 'bg-red-40 res-exists';
      return {
        // using ! to preserve past behavior
        date: reservations[0]!.startTime,
        tooltip: canUsePartialDay ? this.tooltipHTML(reservations) : 'Reservation exists',
        class: bgColor + ' rounded-full py-1 px-1.5 text-white',
        disabled: canUsePartialDay || isCurrentRes ? false : true,
      };
    });
  }

  get currentReservationStartTime(): Date {
    return fromUnixTime(this.args.currentReservation?.startTime);
  }

  get isCurrentResSelected(): boolean {
    const { selectedDates, isEditing } = this.args;

    const officeStartTime = subMinutes(
      this.currentReservationStartTime,
      this.state.minutesBetweenTimezones(this.currentReservationStartTime),
    );
    return selectedDates.some(
      (date) => isEditing && isSameDay(officeStartTime, subMinutes(date, this.state.minutesBetweenTimezones(date))),
    );
  }

  get resourceTypeOptions(): { name: string; code: string; disabled?: boolean }[] {
    return [
      { name: 'Desk', code: 'Desk' },
      { name: 'Parking (coming soon)', code: 'Parking', disabled: true },
    ];
  }

  @action onResourceTypeSelect(option: { name: string; code: string; disabled?: boolean }): void {
    this.selectedResourceType = option;
  }

  tooltipHTML(reservations: ReservationModel[]): SafeString {
    const resDetails = reservations.map((res) => {
      const startTime = fromUnixTime(res.startTime);
      const endTime = fromUnixTime(res.endTime);
      const resStart = subMinutes(startTime, this.state.minutesBetweenTimezones(startTime));
      const resEnd = subMinutes(endTime, this.state.minutesBetweenTimezones(endTime));
      return `<div>${format(resStart, 'h:mma')} to ${format(resEnd, 'h:mma')}</div>`;
    });
    return htmlSafe(`
    <div data-test-day-tooltip>
      <div>Reserved</div>
      ${resDetails.join('')}
    </div>`);
  }

  get reservationExistsOnSelectedDate(): boolean {
    const { selectedDates, currentReservation, isEditing } = this.args;
    return this.reservationsForMonth.some((res) => {
      if (isEditing && this.isCurrentResSelected && res.id === currentReservation?.id) {
        return false;
      }
      const startTime = fromUnixTime(res.startTime);
      return selectedDates.some((date) =>
        isSameDay(
          subMinutes(startTime, this.state.minutesBetweenTimezones(startTime)),
          subMinutes(date, this.state.minutesBetweenTimezones(date)),
        ),
      );
    });
  }

  get selectedDatesLocationTime(): string[] {
    return this.datesToDisplay.map((date) => {
      const currDate = new Date(date as Date);
      return format(subMinutes(currDate, this.state.minutesBetweenTimezones(currDate)), 'MM/dd/yyyy');
    });
  }

  get hasError(): boolean {
    return !!(this.reservationErrors.length || this.partialDayError);
  }

  get reservationsForMonth(): ReservationModel[] {
    const { allDayEnabled, selectedTime } = this.args;
    const reservations = this.loadReservationsForMonthTask?.lastSuccessful?.value || [];
    if (allDayEnabled) {
      return reservations;
    }

    const partialReservations = reservations.filter((res) => {
      const resStartTime = fromUnixTime(res.startTime);
      const midnight = this.state.getOfficeLocationTime(
        startOfDay(subMinutes(resStartTime, this.state.minutesBetweenTimezones(resStartTime))),
      );
      const [partialStart, partialEnd] = getPartialTimes(midnight, selectedTime);
      const startTime = getUnixTime(partialStart!);
      const endTime = getUnixTime(partialEnd!);
      return res.startTime < endTime && res.endTime > startTime;
    });
    return partialReservations;
  }

  get dateAvailabilityArray(): { 'start-time': number; 'end-time': number }[] {
    const { selectedDates, allDayEnabled, selectedTime } = this.args;
    return selectedDates.map((date) => {
      const midnight = this.state.getOfficeLocationTime(
        // using ! to preserve past behavior
        startOfDay(toZonedTime(date, this.state.currentLocation.timezone)),
      );
      const [partialStart, partialEnd] = getPartialTimes(midnight, selectedTime);
      const startTime = allDayEnabled ? midnight : partialStart!;
      const endTime = allDayEnabled
        ? // using ! to preserve past behavior
          this.state.getOfficeLocationTime(endOfDay(toZonedTime(date, this.state.currentLocation.timezone)))
        : partialEnd!;
      return { 'start-time': getUnixTime(startTime), 'end-time': getUnixTime(endTime) };
    });
  }

  get disableNextButton(): string | number | boolean {
    const { selectedEmployee, createReservationTask, floorsWithMap, autoAssignDesksEnabled, isDbeam } = this.args;
    if (isDbeam) {
      return (
        !selectedEmployee || this.hasError || this.onEmployeeSelectTask.isRunning || createReservationTask.isRunning
      );
    }

    const alwaysDisabledConditions =
      !selectedEmployee ||
      this.hasError ||
      this.onEmployeeSelectTask.isRunning ||
      createReservationTask.isRunning ||
      (!floorsWithMap.length && !autoAssignDesksEnabled);

    return alwaysDisabledConditions || this.showCalendar || this.checkForAvailableDesksTask.isRunning;
  }

  get startTimePlaceholder(): string | null {
    const { currentReservation } = this.args;
    if (!currentReservation) {
      return null;
    }
    const startDate = this.currentReservationStartTime;
    const officeLocationStart = subMinutes(startDate, this.state.minutesBetweenTimezones(startDate));
    return format(officeLocationStart, 'h:mm aaa');
  }

  get endTimePlaceholder(): string | null {
    const { currentReservation } = this.args;
    if (!currentReservation) {
      return null;
    }
    const endDate = fromUnixTime(currentReservation.endTime);
    const officeLocationEnd = subMinutes(endDate, this.state.minutesBetweenTimezones(endDate));
    return format(officeLocationEnd, 'h:mm aaa');
  }

  @action
  onPartialTimeChange(attr: 'startTime' | 'endTime', option: Option): void {
    const { onPartialTimeChange } = this.args;
    onPartialTimeChange(attr, option.value);
    void this.checkForAvailableDesksTask.perform();
  }

  setPartialTimes(start: string, end: string): void {
    const { onPartialTimeChange } = this.args;
    onPartialTimeChange('startTime', start);
    onPartialTimeChange('endTime', end);
  }

  @action
  onAllDayToggleClick(): void {
    const { onAllDayToggleClick, isEditing, currentReservation } = this.args;
    if (isEditing) {
      const startTime = fromUnixTime(currentReservation.startTime);
      const endTime = fromUnixTime(currentReservation.endTime);
      const formattedStartTime = format(
        subMinutes(startTime, this.state.minutesBetweenTimezones(startTime)),
        'HH:mm:ss',
      );
      const formattedEndTime = format(subMinutes(endTime, this.state.minutesBetweenTimezones(endTime)), 'HH:mm:ss');
      this.setPartialTimes(formattedStartTime, formattedEndTime);
    } else {
      this.setPartialTimes(this.latestStartTime, this.earliestEndTime);
    }
    onAllDayToggleClick();
    void this.loadReservationsForMonthTask.perform();
    void this.checkForAvailableDesksTask.perform();
  }

  @action
  onNextButtonClick(): void {
    const { autoAssignDesksEnabled, isDbeam, createReservationTask, setCurrentPage } = this.args;
    if (autoAssignDesksEnabled || isDbeam) {
      void createReservationTask.perform();
    } else {
      setCurrentPage('map');
    }
  }

  @action
  dateHasError(date: Date): boolean {
    const { allDayEnabled, selectedTime } = this.args;
    const dateTime = new Date(date);
    const locationTime = this.state.getOfficeLocationTime(dateTime);
    const [partialStart, partialEnd] = getPartialTimes(locationTime, selectedTime);

    const startTime = allDayEnabled ? locationTime : partialStart;
    const endTime = allDayEnabled ? this.state.getOfficeLocationTime(endOfDay(dateTime)) : partialEnd;

    const hasNoDeskAvailablities = this.deskAvailabilities.some(
      (date) =>
        parseInt(date['start-time']!) === getUnixTime(startTime!) &&
        parseInt(date['end-time']!) === getUnixTime(endTime!) &&
        date.availability === 'false',
    );

    const hasConflictingReservation = this.reservationsForMonth.some((res) => {
      const startTime = fromUnixTime(res.startTime);
      return isSameDay(subMinutes(startTime, this.state.minutesBetweenTimezones(startTime)), dateTime);
    });
    return hasNoDeskAvailablities || hasConflictingReservation;
  }

  @action
  reasonEmployeeCannotReserve(employee: Employee): string | undefined {
    // eslint-disable-next-line ember/no-get
    if (!isPresent(get(employee, 'user.id'))) {
      return 'No account exists';
    }

    if (this.featureFlags.isEnabled('protect-scheduling-limits') && this.hasNoApprovedDocument(employee)) {
      return 'No approved document';
    }

    return;
  }

  @action
  onMultiSelectClick(): void {
    const { onMultidayToggleClick } = this.args;
    onMultidayToggleClick();
    void this.checkForAvailableDesksTask.perform();
  }

  checkForAvailableDesksTask = task({ restartable: true }, async () => {
    if (!this.isValidBookingHours || !this.dateAvailabilityArray.length) {
      return;
    }

    const url = urlBuilder.rms.getDeskAvailability();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    const response = await this.ajax.request<any>(url, {
      type: 'POST',
      contentType: 'application/vnd.api+json',
      data: JSON.stringify({
        email: this.args.selectedEmployee.email,
        'location-id': this.state.currentLocation.id,
        'availability-ranges': this.dateAvailabilityArray,
      }),
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    this.deskAvailabilities = response.availabilities;
  });

  loadInviteForTodayTask = task({ drop: true }, async () => {
    try {
      const filter = {
        locationId: this.state.currentLocation?.id,
        dateTo: formatISO(addSeconds(this.state.getOfficeLocationTime(startOfDay(new Date())), SECONDS_IN_A_DAY - 1)),
        dateFrom: formatISO(this.state.getOfficeLocationTime(startOfDay(new Date()))),
        email: encodeURIComponent(this.args.selectedEmployee.email),
      };
      const url = urlBuilder.v3.invites.fetchEmployeeInviteOnDate(filter);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      const response = await this.ajax.request<any>(url, {
        type: 'GET',
        contentType: 'application/vnd.api+json',
        headers: { accept: 'application/vnd.api+json' },
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const invite = response.data.firstObject;
      if (invite) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        this.inviteForToday = await this.store.findRecord('invite', invite.id);
      } else {
        this.inviteForToday = null;
      }
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error fetching invite for today', parseErrorForDisplay(e));
      throw e;
    }
  });

  get dateLimits(): { startDate?: number; endDate?: number } {
    const { selectedEmployee } = this.args;
    const limits: { startDate?: number; endDate?: number } = {};
    const endOfRegistration = addMinutes(
      startOfDay(new Date()),
      this.state.currentLocation.registrationEligibilityEndOffset,
    );

    const locationDate = this.state.getOfficeLocationTime(startOfDay(new Date()));

    if (!this.inviteForToday?.preregistrationComplete && isAfter(locationDate, endOfRegistration)) {
      limits['startDate'] = getUnixTime(addDays(locationDate, 1));
    } else {
      limits['startDate'] = getUnixTime(locationDate);
    }

    const employeeExpiration = selectedEmployee.employeeLocations.firstObject!.requiredDocumentApprovalExpiresAt;

    if (employeeExpiration) {
      limits['endDate'] = getUnixTime(new Date(employeeExpiration));
    } else {
      limits['endDate'] = getUnixTime(addYears(locationDate, 2));
    }

    return limits;
  }

  loadReservationsForMonthTask = task({ restartable: true }, async () => {
    const { selectedEmployee } = this.args;
    // calendar shows 6 weeks at a time
    const startDate = getUnixTime(subDays(startOfMonth(this.showingDate), 7));
    const endDate = getUnixTime(addDays(endOfMonth(this.showingDate), 14));
    try {
      const filter = {
        'location-id': this.state.currentLocation?.id,
        'start-date': startDate,
        'end-date': endDate,
        // eslint-disable-next-line ember/no-get
        'user-id': get(selectedEmployee, 'user.id'),
      };
      const reservationParams = {
        filter,
      };
      const reservations = await this.store.query('reservation', reservationParams);
      return reservations.toArray();
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error fetching reservations', parseErrorForDisplay(e));
      throw e;
    }
  });

  @action
  hideOnClickOutside(selector: string): void {
    const outsideClickListener = (
      event: MouseEvent & { target: { id?: string; closest: (selector: string) => HTMLElement } },
    ) => {
      const target = event.target.id ? document.getElementById(event.target.id) : event.target;
      if (!target) {
        removeClickListener();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      } else if (target.closest(selector) === null) {
        this.closeCalendar();
        removeClickListener();
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener as (event: MouseEvent) => void);
    };
    this.showCalendar = true;
    document.addEventListener('click', outsideClickListener as (event: MouseEvent) => void);
  }

  @action
  closeCalendar(): void {
    this.showCalendar = false;
    this.datesToDisplay = this.args.selectedDates;
    void this.checkForAvailableDesksTask.perform();
  }

  get disableBackButton(): boolean {
    return isPast(endOfMonth(subMonths(this.showingDate, 1)));
  }

  get disableNextMonthButton(): boolean {
    return (
      isAfter(startOfMonth(addMonths(this.showingDate, 1)), fromUnixTime(this.dateLimits.endDate!)) ||
      this.checkForAvailableDesksTask.isRunning
    );
  }

  get isAfterBookingHours(): boolean {
    if (!this.selectedForToday) {
      return false;
    }

    const today = format(subMinutes(this.selectedForToday, this.state.minutesBetweenTimezones()), 'EEEE');
    const currentWorkplaceDay = this.workplaceDays.find((day) => day.dayOfWeek === today);
    const [, partialEnd] = getPartialTimes(startOfDay(new Date()), {
      startTime: currentWorkplaceDay!.startTime,
      endTime: currentWorkplaceDay!.endTime,
    });

    return isAfter(this.state.getOfficeLocationTime(new Date()), this.state.getOfficeLocationTime(partialEnd!));
  }

  get isValidBookingHours(): boolean {
    const { selectedTime } = this.args;
    return selectedTime.startTime.localeCompare(selectedTime.endTime) === -1;
  }

  /**
   * Used for the template
   */
  get partialDayError(): string {
    const { isEditing, selectedEmployee, allDayEnabled, canUsePartialDay } = this.args;
    if (
      (!isEditing || canUsePartialDay) &&
      this.reservationExistsOnSelectedDate &&
      selectedEmployee &&
      !allDayEnabled
    ) {
      return `Selected time overlaps with existing reservation`;
    }
    return '';
  }

  computeReservationErrorForNoDeskAvailability(selectedEmployee: Employee, multidayEnabled: boolean): string | null {
    if (!this.deskAvailabilities.find((desk) => desk.availability === 'false')) {
      return null;
    }

    return `No desks available for ${selectedEmployee.name} on ${multidayEnabled ? 'highlighted dates' : 'this date'}`;
  }

  computeReservationErrorsForEmployeeLogTab(context: EmployeeLogTabReservationValidationContext): string[] {
    const { allDayEnabled, canUsePartialDay, isEditing, multidayEnabled, selectedEmployee, autoAssignDesksEnabled } =
      context;

    const partialDayError = this.computeReservationErrorForPartialDay(context);
    if (partialDayError) {
      return [partialDayError];
    }

    // TODO: this will change
    if ((!isEditing || canUsePartialDay) && this.reservationExistsOnSelectedDate && selectedEmployee && allDayEnabled) {
      return [`${selectedEmployee.name} already has a reservation for this date`];
    }

    if (!autoAssignDesksEnabled) {
      // we're not attempting to book a desk at all
      return [];
    }

    if (!selectedEmployee) {
      return [];
    }

    const deskAvailabilityError = this.computeReservationErrorForNoDeskAvailability(selectedEmployee, multidayEnabled);
    if (deskAvailabilityError) {
      return [deskAvailabilityError];
    }

    return [];
  }

  computeReservationErrorsForDeskReservationTab(context: DeskTabReservationValidationContext): string[] {
    const {
      floorsWithMap,
      allDayEnabled,
      canUsePartialDay,
      isEditing,
      multidayEnabled,
      selectedEmployee,
      autoAssignDesksEnabled,
    } = context;
    if (!floorsWithMap.length && !autoAssignDesksEnabled) {
      return [`Upload a floor map or enable auto assign desks to create reservations`];
    }

    const partialDayError = this.computeReservationErrorForPartialDay(context);
    if (partialDayError) {
      return [partialDayError];
    }

    if ((!isEditing || canUsePartialDay) && this.reservationExistsOnSelectedDate && selectedEmployee && allDayEnabled) {
      return [`${selectedEmployee.name} already has a reservation for this date`];
    }

    if (!selectedEmployee) {
      return [];
    }

    const deskAvailabilityError = this.computeReservationErrorForNoDeskAvailability(selectedEmployee, multidayEnabled);
    if (deskAvailabilityError) {
      return [deskAvailabilityError];
    }

    return [];
  }

  /**
   * @returns `string` if there's an error or `null` otherwise. Will always return no error if `canUsePartialDay` is falsey
   */
  computeReservationErrorForPartialDay(
    context: EmployeeLogTabReservationValidationContext | DeskTabReservationValidationContext,
  ): string | null {
    const { canUsePartialDay, allDayEnabled } = context;

    if (!canUsePartialDay) {
      return null;
    }

    if (this.isInvalidDaySelected) {
      return `Cannot reserve a desk on days with inactive booking hours`;
    } else if (this.isAfterBookingHours) {
      return `Cannot reserve a desk after booking hours for today`;
    } else if (!this.isValidBookingHours && !allDayEnabled) {
      return `There is no overlap between booking hours for the days selected`;
    }

    return null;
  }

  /**
   * @param selectedDates required to be non-empty to even start validation
   * @returns a context that can be used to validate the intention to make a reservation
   */
  createReservationValidationContext(
    selectedDates: NonEmptyArray<Date>,
  ): EmployeeLogTabReservationValidationContext | DeskTabReservationValidationContext {
    const {
      selectedEmployee,
      multidayEnabled,
      isEditing,
      canUsePartialDay,
      allDayEnabled,
      floorsWithMap,
      autoAssignDesksEnabled,
      isDbeam,
    } = this.args;

    const sharedContext = {
      selectedEmployee,
      selectedDates,
      multidayEnabled,
      isEditing,
      canUsePartialDay,
      allDayEnabled,
      floorsWithMap,
      autoAssignDesksEnabled,
    };

    return isDbeam
      ? {
          parentTab: 'employee_log',
          isDbeam,
          ...sharedContext,
        }
      : {
          parentTab: 'desk_reservations',
          isDbeam,
          ...sharedContext,
        };
  }

  /**
   * If the array.length > 0, then there's an error with making a reservation here
   */
  get reservationErrors(): string[] {
    const { selectedDates } = this.args;

    if (!isNonEmptyArray(selectedDates)) {
      return [`Cannot reserve a desk without selecting a date`];
    }

    const validationContext = this.createReservationValidationContext(selectedDates);
    switch (validationContext.parentTab) {
      case 'employee_log':
        return this.computeReservationErrorsForEmployeeLogTab(validationContext);
      case 'desk_reservations':
        return this.computeReservationErrorsForDeskReservationTab(validationContext);
    }
  }

  hasNoApprovedDocument(employee: Employee): boolean {
    const { selectedDates } = this.args;
    const currentEmployeeLocation = this.getCurrentEmployeeLocation(employee.employeeLocations);
    const expiresAt = currentEmployeeLocation?.requiredDocumentApprovalExpiresAt;
    if (expiresAt) {
      return selectedDates.some((date) => isAfter(date, new Date(expiresAt)));
    } else {
      return !currentEmployeeLocation?.hasRequiredDocumentApproval;
    }
  }

  getCurrentEmployeeLocation(
    employeeLocations: EmberArray<EmployeeLocation> = [] as unknown as EmberArray<EmployeeLocation>,
  ): EmployeeLocation | undefined {
    const currentLocationId = parseInt(this.state.currentLocation?.id ?? '', 10);

    if (!(currentLocationId && typeof employeeLocations.findBy === 'function')) {
      return;
    }

    return employeeLocations.findBy('locationId', currentLocationId);
  }

  goBackOneMonthTask = task({ drop: true }, async () => {
    this.showingDate = subMonths(this.showingDate, 1);
    await this.loadReservationsForMonthTask.perform();
  });

  goToNextMonthTask = task({ drop: true }, async () => {
    this.showingDate = addMonths(this.showingDate, 1);
    await this.loadReservationsForMonthTask.perform();
  });

  setCorrectPartialTimes(): void {
    const { selectedTime } = this.args;
    if (selectedTime.startTime >= selectedTime.endTime) {
      this.setPartialTimes(this.latestStartTime, this.earliestEndTime);
    }
    const startTime =
      selectedTime.startTime > this.latestStartTime && selectedTime.startTime < this.earliestEndTime
        ? selectedTime.startTime
        : this.latestStartTime;
    const endTime =
      selectedTime.endTime < this.earliestEndTime && selectedTime.endTime > this.latestStartTime
        ? selectedTime.endTime
        : this.earliestEndTime;
    this.setPartialTimes(startTime, endTime);
  }

  @action
  removeDate(dateToRemove: string, event: Event): void {
    const { onDateSelect } = this.args;
    onDateSelect(this.state.getOfficeLocationTime(new Date(dateToRemove)));
    this.setCorrectPartialTimes();
    this.datesToDisplay = this.datesToDisplay.filter(
      (date) => !isSameDay(date as Date, this.state.getOfficeLocationTime(new Date(dateToRemove))),
    );
    void this.checkForAvailableDesksTask.perform();
    event.stopPropagation();
  }

  onSelectDateTask = task({ drop: true }, async (selectedDate: moment.Moment) => {
    const { onDateSelect, multidayEnabled } = this.args;

    onDateSelect(selectedDate);
    this.setCorrectPartialTimes();
    const epochMilli = selectedDate.unix() * 1000;
    if (!multidayEnabled) {
      this.showCalendar = false;
      this.datesToDisplay = [selectedDate];
      await this.checkForAvailableDesksTask.perform();
    } else if (!isSameMonth(new Date(epochMilli), this.showingDate)) {
      void this.loadReservationsForMonthTask.perform();
    }

    this.showingDate = new Date(epochMilli);
  });

  onEmployeeSelectTask = dropTask(
    waitFor(async (employee: Employee) => {
      const { onEmployeeSelect, onDateSelect, selectedDates } = this.args;
      onEmployeeSelect(employee);
      await this.loadInviteForTodayTask.perform();
      if (getUnixTime(selectedDates[0]!) < this.dateLimits.startDate!) {
        onDateSelect(this.dateLimits.startDate! * 1000);
      }
      this.datesToDisplay = selectedDates;
      await this.loadReservationsForMonthTask.perform();
      await this.checkForAvailableDesksTask.perform();
    }),
  );

  loadEmployeeReservationsTask = dropTask(
    waitFor(async () => {
      const { isEditing, getSelectedEmployeeTask, selectedEmployee } = this.args;
      if (selectedEmployee) {
        return;
      }

      await getSelectedEmployeeTask.perform();
      if (!isEditing) {
        this.setPartialTimes(this.startHourOptions[0]!.value, this.earliestEndTime);
        return;
      }

      await this.loadInviteForTodayTask.perform();
      await this.loadReservationsForMonthTask.perform();
      await this.checkForAvailableDesksTask.perform();
    }),
  );

  onEmployeeSearchTask = task(async (term: string) => {
    const { searchTask } = this.args;
    if (term) {
      this.employees = await searchTask.perform(term);
    } else {
      this.employees = this.loadedEmployees;
    }
  });

  // This function is need for the validateUserInput param in the comboBox.
  // The default for the param is a function that returns true.
  @action
  returnFalseValid(): false {
    return false;
  }
}
