import Model, { attr, belongsTo, hasMany, type AsyncHasMany } from '@ember-data/model';
import type AmenityModel from 'garaje/models/amenity';
import type AssignmentModel from 'garaje/models/assignment';
import type FloorModel from 'garaje/models/floor';
import type MapFeaturePoint from 'garaje/models/map-feature';
import adapter from 'garaje/utils/decorators/adapter';
import RelationshipsTracker from 'garaje/utils/decorators/relationships-tracker';
import serializer from 'garaje/utils/decorators/serializer';

// Utility function to extract an ID from a URN field
// i.e. urn:envoy:location:2069 -> 1234
export function getIdFromURN(urnField: string | undefined): string | undefined {
  if (!urnField) {
    return undefined;
  }

  const fieldSegments = urnField.split(':');
  return fieldSegments[fieldSegments.length - 1];
}

@RelationshipsTracker
@adapter('generics')
@serializer('generic-resource')
class GenericResourceModel extends Model {
  // Attributes
  @attr('string') declare name: string;
  @attr('number') declare resourceTypeId: number;
  @attr('string') declare relationId: string;
  @attr declare geometry: MapFeaturePoint;
  @attr('date') declare createdAt: string;
  @attr('date') declare updatedAt: string;
  @attr('date') declare deletedAt: string | null;

  // Relationships
  @belongsTo('floor', { async: true }) declare floor: FloorModel;
  @hasMany('amenity', { async: true }) declare amenities: AsyncHasMany<AmenityModel>;
  @hasMany('assignment', { async: true }) declare assignments: AsyncHasMany<AssignmentModel>;
}

export default GenericResourceModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'generic-resource': GenericResourceModel;
  }
}
