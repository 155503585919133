import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import { defer } from 'rsvp';
import { action, get, set } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import EmberObject from '@ember/object';
import Changeset from 'ember-changeset';
import { all, task, dropTask } from 'ember-concurrency';
import { reject } from 'rsvp';
import { isRTLLocale } from 'garaje/utils/locale-options';
import { SIGN_IN_FIELD_KINDS, STORE_RESPONSE_CONFIG_OPTIONS } from 'garaje/utils/enums';
import { isAppUpdateRequired } from 'garaje/utils/check-app-version';
import _intersectionBy from 'lodash/intersectionBy';
import { v1 as uuidV1 } from 'uuid';
import { cached } from 'tracked-toolbox';
import { APP } from 'garaje/utils/enums';

const MINIMUM_IPAD_VERSION_NEEDED = '3.6.0';

/**
 * @param {Array<Object>}               signInFields
 * @param {Array<Object>}               signInFieldActionRuleGroups
 * @param {Class<Flow>}                 flow
 * @param {Array<Object>}               ipads
 * @param {Class<Location>}             currentLocation
 * @param {Function}                    registerChangeset
 * @param {Boolean}                     embedded
 * @param {Boolean}                     isDisabled
 * @param {Boolean}                     isLocationAdmin
 * @param {Boolean}                     defaultStoreResponse
 * @param {Array<Object>}               overrideOptions
 * @param {Array<String>}               languagesAvailable
 * @param {Boolean}                     allowRenderTranslationButton
 * @param {Boolean}                     translationLanguagesAvailable
 * @param {Array<String>}               translationEnabledLocales
 * @param {Object}                      visualComplianceConfiguration
 * @param {Object}                      globalSettingBatch
 * @param {Boolean}                     isAppUpdateRequired
 * @param {Boolean}                     isRTL
 * @param {Boolean}                     isEmailOrPhoneRemovable
 * @param {Function}                    handleFieldOptionsSectionChange
 */
export default class FormPage extends Component {
  @service flashMessages;
  @service cookies;
  @service featureFlags;
  @service store;
  @service currentAdmin;
  @service('setupGuideStepper') setupGuideStepperService;
  @service visitorsOnboarding;

  @tracked recalculateDragContainer = false;
  @tracked rulesModalShowing = false;
  @tracked unsavedFieldsModalForRulesShowing = false;
  @tracked unsavedFieldsModalForFieldOptionsShowing = false;
  @tracked responseOptionsModalShowing = false;
  @tracked unsavedFieldsModalForResponseOptionsShowing = false;

  @isAppUpdateRequired('args.ipads', MINIMUM_IPAD_VERSION_NEEDED) _isAppUpdateRequired;

  get isAppUpdateRequired() {
    return this.args.isAppUpdateRequired ?? this._isAppUpdateRequired;
  }

  get config() {
    return this.args.currentLocation?.config;
  }

  get dirtyFields() {
    return this.args.signInFields?.filter((signInField) => {
      return get(signInField, 'hasDirtyAttributes') || signInField.hasDirtyOptions || get(signInField, 'isDeleted');
    });
  }

  get dirtyDropdowns() {
    return this.dirtyFields.filterBy('kind', SIGN_IN_FIELD_KINDS.SINGLE_SELECTION);
  }

  get hasDirtyAttributes() {
    return this.dirtyFields.length > 0;
  }

  get hasConditionals() {
    return this.topLevelFields.length != this.customChangesetFields.length;
  }

  get isRTL() {
    return this.args.isRTL ?? isRTLLocale(this.args.currentLocation.locale);
  }

  get persistedRules() {
    const { flow } = this.args;
    const ruleGroupModelType = flow.isGlobal
      ? 'global-sign-in-field-action-rule-group'
      : 'sign-in-field-action-rule-group';

    // Gather rules from route model query response and from the Ember Data store
    const argRules = this.args.signInFieldActionRuleGroups || [];
    const storeRules = this.store.peekAll(ruleGroupModelType);

    // Rules for Global Child Flows are "read-only". Simply return the route model
    // query response.
    // Otherwise, find all the records in the store that belong to the @flow. Rules
    // returned by the route query are *not* aware of their flow relationship.
    // Thus, all this trickery to combine new and existing records.
    return flow.isGlobalChild
      ? argRules
      : storeRules.filter((rule) => {
          // If the rule is not persisted, EXCLUDE the rule
          if (rule.isNew) return false;
          if (rule.isDeleted) return false;

          // If included in filtered query response, include the rule
          if (argRules.includes(rule)) return true;

          // If the rule belongs to @flow, include the rule
          return rule.belongsTo('flow').id() === flow?.id;
        });
  }

  // fake changeset implementing the min interface required by
  // dirty-check.
  get changeset() {
    const pseudoChangeset = EmberObject.create({
      isDirty: this.hasDirtyAttributes || this.customChangesetFields.isAny('isInvalid'),
    });

    pseudoChangeset.rollbackAttributes = () => {
      this.args.signInFields.invoke('rollbackAttributes');
      // we need this, in case there is a custom field with an empty
      // value.
      this.customChangesetFields.invoke('rollback');
      pseudoChangeset.isDirty = false;
    };

    if (this.args.registerChangeset) {
      this.args.registerChangeset(pseudoChangeset);
    }

    return pseudoChangeset;
  }

  get fieldOptions() {
    if (this.args.overrideOptions) {
      return this.args.overrideOptions;
    }

    const options = [{ name: 'Dropdown', kind: SIGN_IN_FIELD_KINDS.SINGLE_SELECTION }];
    if (!this.args.embedded) {
      options.unshift({ name: 'Short answer', kind: SIGN_IN_FIELD_KINDS.TEXT });
    }
    if (!this.args.embedded && (!this.hostField || get(this.hostField, 'isDeleted'))) {
      options.unshift({
        name: 'Host',
        value: 'Host',
        kind: SIGN_IN_FIELD_KINDS.TEXT,
        identifier: 'host',
        field: this.hostField,
      });
    }
    if ((!this.args.embedded && !this.phoneField) || get(this.phoneField, 'isDeleted')) {
      options.unshift({
        name: 'Phone',
        value: 'Your Phone Number',
        identifier: 'phone',
        kind: SIGN_IN_FIELD_KINDS.PHONE,
        field: this.phoneField,
      });
    }
    if (!this.emailField || get(this.emailField, 'isDeleted')) {
      options.unshift({
        name: 'Email',
        value: 'Your Email Address',
        kind: SIGN_IN_FIELD_KINDS.EMAIL,
        field: this.emailField,
      });
    }

    return options;
  }

  @cached
  get customChangesetFields() {
    return this.args.signInFields
      .filter((field) => !get(field, 'isDeleted') && get(field, 'isCustom'))
      .map((field) => new Changeset(field, this.validateField));
  }

  // Fields without a parent field
  get topLevelFields() {
    return this.customChangesetFields
      .filter((field) => get(field, 'actionableSignInFieldActions.length') === 0)
      .sortBy('position');
  }

  get canSave() {
    if (this.customChangesetFields.isAny('isInvalid')) {
      return false;
    }

    return this.hasDirtyAttributes || this.customChangesetFields.isAny('isDirty');
  }

  get impactedRulesOnSave() {
    if (this.featureFlags.isEnabled('ignoreRules')) {
      const impactedActions = this.dirtyDropdowns.reduce(
        (actions, field) => actions.concat(field.signInFieldActions.filterBy('isRule')),
        [],
      );

      return this.args.signInFieldActionRuleGroups?.filter((rule) =>
        isPresent(_intersectionBy(impactedActions, rule.signInFieldActions.toArray(), 'id')),
      );
    }

    return [];
  }

  get hasEmailXorPhone() {
    const hasEmail = this.emailField ? !get(this.emailField, 'isDeleted') : false;
    const hasPhone = this.phoneField ? !get(this.phoneField, 'isDeleted') : false;

    return (hasEmail && !hasPhone) || (!hasEmail && hasPhone);
  }

  // BHB relies on email, but if email isn't enabled, it relies on phone. We need one of those two.
  get isEmailOrPhoneRemovable() {
    if (this.args.isDisabled) return false;
    return this.args.isEmailOrPhoneRemovable ?? !(get(this.config, 'beenHereBefore') && this.hasEmailXorPhone);
  }

  get emailField() {
    return this.args.signInFields.findBy('kind', SIGN_IN_FIELD_KINDS.EMAIL);
  }

  get phoneField() {
    return this.args.signInFields.findBy('kind', SIGN_IN_FIELD_KINDS.PHONE);
  }

  get hostField() {
    return this.args.signInFields.findBy('identifier', 'host');
  }

  get showEmailPhoneReminder() {
    return this.args.isGlobalFlow && !this.emailField && !this.phoneField;
  }

  get fieldOptionsModalFeatureEnabled() {
    return !get(this.args, 'flow.isProtect');
  }

  @dropTask
  confirmWillAlterRulesTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  @dropTask
  *confirmWillAlterRulesContinueTask() {
    yield this.confirmWillAlterRulesTask.last.continue();
  }

  @action
  getDefaultStoreResponse(kind, identifier) {
    // Response options are only configurable for non-host field dropdowns.
    if (identifier === 'host' || kind !== SIGN_IN_FIELD_KINDS.SINGLE_SELECTION) {
      return true;
    }

    switch (this.args.flow.storeResponseConfig) {
      case STORE_RESPONSE_CONFIG_OPTIONS.MANAGED:
        return Boolean(this.args.defaultStoreResponse);
      case STORE_RESPONSE_CONFIG_OPTIONS.DISCARDED:
        return false;
      case STORE_RESPONSE_CONFIG_OPTIONS.STORED:
        return true;
      default:
        return Boolean(this.args.defaultStoreResponse);
    }
  }

  @action
  async deleteField(field) {
    // Delete any sign in field action
    const actionableSignInFieldActions = await get(field, 'actionableSignInFieldActions');
    actionableSignInFieldActions.forEach((action) => action.deleteRecord());
    field.deleteRecord();
  }

  @dropTask
  *saveFieldsTask(e) {
    e?.preventDefault();
    this.visitorsOnboarding.waitToLoadFlows = true;

    let dirtySignInFieldActionRuleGroups;
    let openRulesModalAfterSave = false;

    if (this.featureFlags.isEnabled('ignoreRules')) {
      if (this.impactedRulesOnSave.length) {
        const confirmed = yield this.confirmWillAlterRulesTask.perform();

        if (!confirmed) {
          return;
        }

        openRulesModalAfterSave = true;
      }

      dirtySignInFieldActionRuleGroups = this.impactedRulesOnSave;
    }

    const {
      args: { signInFields },
      cookies: cookieService,
    } = this;

    this.reorderCustomFields(this.topLevelFields);

    const dirtySignInFields = this.dirtyFields;
    const dirtyParentFields = dirtySignInFields
      .filter((field) => field.actionableSignInFieldActions.length === 0 && !field.isDeleted)
      .sortBy('position');
    const deletedParentFields = dirtySignInFields
      .filter((field) => field.actionableSignInFieldActions.length === 0 && field.isDeleted)
      .sortBy('position');
    const dirtyChildFields = dirtySignInFields
      .filter((field) => field.actionableSignInFieldActions.length > 0 && !field.isDeleted)
      .sortBy('position');
    const deletedChildFields = dirtySignInFields
      .filter((field) => field.actionableSignInFieldActions.length > 0 && field.isDeleted)
      .sortBy('position');
    const dirtySignInFieldActions = this._dirtySignInFieldActions(signInFields);
    const translatableChanged = dirtySignInFields.some((dirty) => {
      const changed = Object.keys(dirty.changedAttributes());

      return changed.includes('name') || changed.includes('options') || dirty.hasDirtyOptions;
    });

    cookieService.write('X-Request-ID', uuidV1());

    try {
      // Note: need to delete child fields before deleting parent fields since backend will delete
      // child fields when deleting the parent field which will lead to a 404 response when sending
      // the delete child field request
      yield all(deletedChildFields.invoke('save'));
      yield all(deletedParentFields.invoke('save'));

      // Note: when creating a conditional field the request to update to the parent field must
      // be completed before the request to create the child field is completed. if the requests
      // happen out of order, it can lead to this bug VIS-6807
      yield all(dirtyParentFields.invoke('save'));
      yield all(dirtyChildFields.invoke('save'));

      try {
        yield all(
          dirtySignInFieldActions.map((action) => {
            set(action, 'signInFieldValue', action.dropdownOption.id); // make sure the id is always set
            set(action, '_dropdownOption', null); // Clean the cached value

            return action.save();
          }),
        );
      } catch (e) {
        if (
          (e.message?.indexOf('Attempted to handle event') > 0 &&
            e.message?.indexOf('while in state root.empty') > 0) ||
          e.message?.indexOf('as there is no such record in the cache') > 0
        ) {
          // eslint-disable-next-line no-console
          console.debug('Error saving signInFieldActions: ', e);
        } else {
          throw e;
        }
      }

      if (this.featureFlags.isEnabled('ignoreRules')) {
        yield all(
          dirtySignInFieldActionRuleGroups.map((rule) => {
            set(rule, 'ignore', true);
            return rule.save();
          }),
        );
      }

      // TODO: translationLanguagesAvailable vs languagesAvailable, Hmmmmmmm
      const subText =
        translatableChanged && this.args.languagesAvailable && !this.args.embedded
          ? 'Your translations were updated to match.'
          : '';

      if (this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')) {
        this.setupGuideStepperService.loadSetupStepsTask.perform(APP.VISITORS);
      }
      this.flashMessages.showAndHideFlash('success', 'Saved!', subText, undefined, true);

      if (!this.featureFlags.isEnabled('hidePropagableChanges')) {
        this._handleManualPropagation(dirtySignInFields);
      }

      if (openRulesModalAfterSave) {
        this.rulesModalShowing = true;
      }

      if (this.responseOptionsModalShowing) {
        this.responseOptionsModalShowing = false;
      }
    } catch (e) {
      let message = 'Server error. Please try again.';

      // eslint-disable-next-line no-console
      console.log({ e });

      if (e.isAdapterError) {
        message = e.errors.mapBy('detail').join(', ');
      }

      this.flashMessages.showFlash('error', message, undefined, undefined, true);

      yield reject();
    }

    cookieService.clear('X-Request-ID');
    this.visitorsOnboarding.waitToLoadFlows = false;
  }

  @task
  *saveForRulesTask() {
    try {
      yield this.saveFieldsTask.perform();
      this.unsavedFieldsModalForRulesShowing = false;
      this.rulesModalShowing = true;
    } catch (err) {
      // when error, defaults to user having to cancel the confirmation modal for now
    }
  }

  @task
  *saveForResponseOptionsTask() {
    try {
      yield this.saveFieldsTask.perform();
      this.unsavedFieldsModalForResponseOptionsShowing = false;
      this.responseOptionsModalShowing = true;
    } catch (err) {
      // when error, defaults to user having to cancel the confirmation modal for now
    }
  }

  @task
  *saveForFieldOptionsTask() {
    try {
      yield this.saveFieldsTask.perform();
      this.unsavedFieldsModalForFieldOptionsShowing = false;
      this.notifySectionChange('data-storage');
    } catch (err) {
      // when error, defaults to user having to cancel the confirmation modal for now
    }
  }

  notifySectionChange(name) {
    if (typeof this.args.handleFieldOptionsSectionChange === 'function') {
      this.args.handleFieldOptionsSectionChange(name);
    }
  }

  // After saving a conditional field, the PATCH response disassociates the
  // conditional field from its parent in the Ember Data Store. This causes
  // `get(field, 'nonDeletedActionableSignInFields')` to return an incomplete array.
  // This method finds custom conditional fields by "parent" ID, instead.
  conditionalFieldsForField(field) {
    return this.customChangesetFields
      .filter((f) => {
        // Exclude records marked for deletion
        if (f.isDeleted) {
          return false;
        }

        return get(f, 'actionableSignInFieldActions').findBy('signInField.id', field.id);
      })
      .sortBy('position');
  }

  @action
  reorderCustomFields(newFields) {
    if (this.args.isDisabled) {
      return;
    }

    /*
     Use the first custom field position as the offset. Name, email
     and phone will always come first, but some of those fields might
     not always be present.
     */
    const customFieldsFirstPosition = get(this.topLevelFields, 'firstObject.position');

    let currentPosition = customFieldsFirstPosition;

    newFields.forEach((field) => {
      const conditionalFields = this.conditionalFieldsForField(field);

      set(field, 'position', currentPosition);

      conditionalFields.forEach((conditionalField) => {
        currentPosition += 1;
        set(conditionalField, 'position', currentPosition);
      });

      currentPosition += 1;
    });
  }

  @action
  toggleRecalculateContainer() {
    this.recalculateDragContainer = !this.recalculateDragContainer;
  }

  @action
  recalculateDraggableContainer() {
    const parent = document.getElementById('visitor-information');
    parent.removeAttribute('style');
  }

  @action
  showRulesModal() {
    if (this.hasDirtyAttributes) {
      this.unsavedFieldsModalForRulesShowing = true;
    } else {
      this.rulesModalShowing = true;
    }
  }

  @action
  showFieldOptionsModal() {
    if (this.hasDirtyAttributes) {
      this.unsavedFieldsModalForFieldOptionsShowing = true;
    } else {
      this.notifySectionChange('data-storage');
    }
  }

  @action
  showResponseOptionsModal() {
    if (this.hasDirtyAttributes) {
      this.unsavedFieldsModalForResponseOptionsShowing = true;
    } else {
      this.responseOptionsModalShowing = true;
    }
  }

  validateField({ key, newValue, content }) {
    let isValid = true;

    if (key === 'name' && isEmpty(newValue)) {
      isValid = false;
    } else if (key === 'name' && (newValue || '').toLowerCase() === 'purpose of visit') {
      // 'purpose of visit' is a reserved phrase and can not be used in custom field
      isValid = false;
    } else {
      // we want changeset only to make sure name is valid -- if it
      // is, then we apply the change to the model
      set(content, key, newValue);
    }
    return isValid;
  }

  @action
  toggleEmailRequired(required) {
    this.emailField.required = required;
  }

  @action
  togglePhoneRequired(required) {
    this.phoneField.required = required;
  }

  // Return all Non deleted and dirty actions
  _dirtySignInFieldActions(signInFields) {
    return signInFields
      .reduce((acc, signInField) => {
        return [
          ...acc,
          ...get(signInField, 'actionableSignInFieldActions').filter((action) => {
            return get(action, 'hasDirtyAttributes') && !get(action, 'isDeleted');
          }),
        ];
      }, [])
      .filter(Boolean);
  }

  _handleManualPropagation(signInFields) {
    const globalSettingBatch = this.args.globalSettingBatch;
    if (globalSettingBatch) {
      const fieldsIds = new Set(get(globalSettingBatch, 'signInFields'));
      signInFields.forEach((signInField) => fieldsIds.add(get(signInField, 'id')));
      set(globalSettingBatch, 'signInFields', [...fieldsIds]);
    }
  }
}
