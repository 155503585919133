import type NativeArray from '@ember/array/-private/native-array';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import { isValid, parse, parseISO } from 'date-fns';
import type AbilitiesService from 'ember-can/services/abilities';
import { didCancel, all } from 'ember-concurrency';
import type FlowModel from 'garaje/models/flow';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type PrinterModel from 'garaje/models/printer';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SignInFieldPageModel from 'garaje/models/sign-in-field-page';
import type SubscriptionModel from 'garaje/models/subscription';
import type TenantModel from 'garaje/models/tenant';
import type UserModel from 'garaje/models/user';
import type StateService from 'garaje/services/state';
import { DATE_FNS_YYYY_MM_DD } from 'garaje/utils/date-fns-tz-utilities';
import type { RecordArray } from 'garaje/utils/type-utils';
import moment from 'moment-timezone';
import { hash } from 'rsvp';

import type { VisitorsInvitesRouteModel } from '../route';

import type VisitorsInvitesIndexController from './controller';

export interface VisitorsInvitesIndexRouteModelParams {
  startDate: string | null;
  endDate: string | null;
  date: string;
  query: string;
  filter: string;
  selectedFlow: string;
  selectedFlows?: string;
  sort: string;
}

export interface VisitorsInvitesIndexRouteModel {
  connectedTenants: RecordArray<TenantModel>;
  flows: FlowModel[];
  invites: RecordArray<InviteModel>;
  printers: RecordArray<PrinterModel>;
  vrSubscription: SubscriptionModel;
  currentLocation: LocationModel;
  blocklistContacts: RecordArray<UserModel>;
  startDate: string | null;
  endDate: string | null;
  date: string;
  sort: string;
  filter: string;
  query: string;
  selectedFlow: string;
  selectedFlows?: string;
  signInFieldPages: SignInFieldPageModel[];
  customFields: NativeArray<SignInFieldModel>;
}

class VisitorsInvitesIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  queryParams = {
    startDate: {
      refreshModel: true,
    },
    endDate: {
      refreshModel: true,
    },
    date: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    filter: {
      refreshModel: true,
    },
    sort: {
      refreshModel: true,
    },
    selectedFlows: {
      as: 'visitor_types',
      refreshModel: true,
    },
    selectedFlow: {
      // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
      as: 'visitor_type',
      refreshModel: true,
    },
  };

  beforeModel(transition: Transition): void {
    const transitionFrom = transition.from?.name;
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = this.controllerFor('visitors.invites.index');

    if (controller && transitionFrom && !transitionFrom.includes('invites')) {
      controller.send('clearSearch');
      void this.router.transitionTo({ queryParams: { query: '', visitor_type: '' } });
    }

    const queryParams: { date?: string | null; startDate?: string | null; endDate?: string | null } = {};
    const dateParam = transition.to.queryParams['date'];
    const startDateParam = transition.to.queryParams['startDate'];
    const endDateParam = transition.to.queryParams['endDate'];
    const hasInvalidDateParam = isPresent(dateParam) && !moment(dateParam).isValid();
    const hasInvalidStartDateParam = isPresent(startDateParam) && !moment(startDateParam).isValid();
    const hasInvalidEndDateParam = isPresent(endDateParam) && !moment(endDateParam).isValid();
    const isDateInvalid = hasInvalidDateParam || hasInvalidStartDateParam || hasInvalidEndDateParam;

    if (hasInvalidDateParam) queryParams.date = null;
    if (hasInvalidStartDateParam) queryParams.startDate = null;
    if (hasInvalidEndDateParam) queryParams.endDate = null;
    if (isDateInvalid) void this.router.transitionTo('visitors.invites.index', { queryParams });
  }

  async model({
    startDate,
    endDate,
    date,
    query,
    filter,
    selectedFlow, // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
    selectedFlows,
    sort,
  }: VisitorsInvitesIndexRouteModelParams): Promise<VisitorsInvitesIndexRouteModel | Transition> {
    const { vrSubscription, currentLocation } = this.state;
    const blocklistContacts = currentLocation.blocklistContacts;

    if (
      (startDate && !isValid(parseISO(startDate))) ||
      (endDate && !isValid(parseISO(endDate))) ||
      (date && !isValid(parse(date, DATE_FNS_YYYY_MM_DD, new Date())))
    ) {
      return this.router.transitionTo('visitors.invites', { queryParams: { date: null } });
    }

    // Printers endpoint returns 403 for unauthorized employees which end up on users
    // getting sad cloud upon sign in
    let printers: PrinterModel[] | Promise<RecordArray<PrinterModel>> = [];
    if (this.abilities.can('visit device')) {
      printers = this.store.query('printer', {
        filter: { location: currentLocation?.id, enabled: true },
        sort: 'status',
      });
    }
    const locationFlows = await currentLocation.flows;
    const signInFieldPages = await all(locationFlows.map((flow) => flow.signInFieldPage));
    const customFields = await all(signInFieldPages.map((signInFieldPage) => signInFieldPage?.customFields));
    const { connectedTenants } = <VisitorsInvitesRouteModel>this.modelFor('visitors.invites');

    const isConnectedToProperty = <number>connectedTenants.length > 0;
    return hash({
      connectedTenants,
      flows: this.state.loadFlows({
        includePropertyFlows: isConnectedToProperty, // if this location is connected to a property, include flows added by the property
        locationId: currentLocation.id,
        reload: false,
      }),
      invites: [],
      printers,
      vrSubscription,
      currentLocation,
      blocklistContacts,
      startDate,
      endDate,
      filter,
      query,
      sort,
      selectedFlow,
      selectedFlows,
      signInFieldPages,
      customFields,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  async setupController(
    controller: VisitorsInvitesIndexController,
    model: VisitorsInvitesIndexRouteModel,
    transition: Transition,
  ): Promise<void> {
    super.setupController(controller, model, transition);

    void controller.refreshLocationsCapacity();

    controller.page = 0;
    controller.limit = 25;
    controller.invitesCount = 0;
    controller.sort = model.sort;
    controller.selectedFlow = model.selectedFlow;

    // Trigger load reviewable task. No need to wait
    void controller.loadReviewableInvitesTask.perform();

    await controller.loadBosses.perform().catch((e) => {
      if (!didCancel(e)) throw e;
    });

    const validFilter = controller.filterOptions.find((option) => option.filter === controller.filter);

    if (!validFilter && controller.filterOptions.length) {
      void this.router.transitionTo({ queryParams: { filter: controller.filterOptions[0]!.filter } });

      return;
    }

    if (!controller.inviteDashboardFieldsByLocationId[model.currentLocation.id]?.length) {
      // track each location's invite dashboard fields by id per controller instance in case the api returns a different set
      controller.inviteDashboardFieldsByLocationId[model.currentLocation.id] = [
        ...model.currentLocation.inviteDashboardFields,
      ];
    }

    return controller.loadInvites.perform().catch((e) => {
      if (!didCancel(e)) throw e;
    });
  }
}

export default VisitorsInvitesIndexRoute;
