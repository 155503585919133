import Route from '@ember/routing/route';
import { service } from '@ember/service';
export default class AnalyticsGlobalSigmaReportsRoute extends Route {
  @service abilities;
  @service router;
  @service featureFlags;
  @service state;
  @service userFeatureFlags;

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.showModal = false;
  }
}
