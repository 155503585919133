import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { isPresent } from '@ember/utils';
import { toLatLngObject, toCoords } from 'garaje/helpers/resource-coords';
import { POLYGON_BASE_OPTIONS, POLYGON_COLORS } from 'garaje/utils/polygon';
import { htmlSafe } from '@ember/template';
import { service } from '@ember/service';
import leaflet from 'leaflet';
import { AvailabilityStatus, ResourceType } from 'garaje/services/live-map';

/**
 * @param {Leaflet<Map>}                map
 * @param {Model<MapFeature>}           feature
 * @param {Array}                       mapDimensions
 * @param {Boolean}                     viewOnly
 * @param {Boolean}                     isVersionMap
 * @param {Model<MapFeature>}           selectedFeature
 * @param {Function}                    onClick
 * @param {Function}                    setIsOverArea // disable interaction to place resource on an area
 * @param {Function}                    onDragStart
 * @param {Function}                    onDrag
 */
export default class ResourceMapArea extends Component {
  @service featureFlags;
  @service featureConfig;
  @service store;
  @service abilities;
  @service liveMap;

  area;
  marker;
  tooltip;
  layerGroup;
  isEditing = false;

  get colorKey() {
    const { feature, viewOnly, isVersionMap } = this.args;
    const room = feature.externalId ? this.store.peekRecord('room', feature.externalId) : '';
    const isRoomViewable = viewOnly && !isVersionMap && room;

    if (this.featureFlags.isEnabled('snapshot-for-live-map')) {
      if (feature.isNew || feature.hasDirtyAttributes || this.isEditing) {
        return 'edit';
      }
      // When using snapshot data, we want to check against the map-feature externalId since the room.id might not exist
      const roomStatus = this.liveMap.getResourceAvailabilityByIdAndType(feature.externalId, ResourceType.ROOM);
      if (roomStatus === AvailabilityStatus.OCCUPIED && isRoomViewable) {
        return 'unavailable';
      }

      if (roomStatus === AvailabilityStatus.AVAILABLE || feature.enabled || isRoomViewable) {
        return 'enabled';
      }

      return 'disabled';
    } else {
      if (isRoomViewable && room.timeAvailableInMinutes < 0) {
        return 'unavailable';
      }

      if (feature.isNew || feature.hasDirtyAttributes || this.isEditing) {
        return 'edit';
      }

      if (feature.enabled || isRoomViewable) {
        return 'enabled';
      }

      return 'disabled';
    }
  }

  get colorOptions() {
    const color = this.colorKey;
    const colors = POLYGON_COLORS[color];

    if (!this.args.viewOnly && this.abilities.cannot('edit maps')) {
      return {
        color: `${colors.border}50`,
        fillColor: `${colors.fill}50`,
      };
    }

    return {
      color: colors.border,
      fillColor: colors.fill,
    };
  }

  get className() {
    const { feature, viewOnly, selectedFeature } = this.args;
    const classes = ['resource-icon-base', feature.type, !feature.isValid || feature.hasError ? 'error' : null];
    const roomIsAvailable = this.roomIsAvailable(feature?.externalId);

    if ((feature.enabled && !viewOnly) || (viewOnly && roomIsAvailable)) {
      classes.push('is-enabled');
    }

    if (viewOnly && !roomIsAvailable) {
      classes.push('is-unavailable');
    }

    if (feature.isNew) {
      classes.push('is-placing');
    }

    if (!feature.enabled) {
      classes.push('is-disabled');
    }

    if (viewOnly && feature === selectedFeature) {
      classes.push('is-selected');
    }

    if (!viewOnly && this.abilities.cannot('edit maps')) {
      classes.push('is-uneditable');
    }

    return classes.compact().join(' ');
  }

  get iconOptions() {
    const { feature, viewOnly, selectedFeature } = this.args;
    const viewOnlyAndSelected = viewOnly && feature === selectedFeature;

    return {
      iconSize: viewOnlyAndSelected ? [45, 45] : [30, 30],
      className: this.className,
      html: this.featureConfig.isEnabled('mapsPointOfInterest') ? this.getRoomHtml() : '',
    };
  }

  roomIsAvailable(externalId) {
    if (!externalId) {
      return false;
    }

    if (this.featureFlags.isEnabled('snapshot-for-live-map')) {
      const roomStatus = this.liveMap.getResourceAvailabilityByIdAndType(externalId, ResourceType.ROOM);
      return roomStatus === AvailabilityStatus.AVAILABLE;
    }

    const room = this.store.peekRecord('room', externalId);
    return room && room?.timeAvailableInMinutes >= 0;
  }

  getLabelColor() {
    const { feature, viewOnly, isVersionMap } = this.args;

    const showViewOnlyColors = !isVersionMap && viewOnly;

    if (!showViewOnlyColors && this.abilities.cannot('edit maps')) {
      return feature.enabled ? 'cilantro-30' : 'carbon-30';
    }

    const roomIsAvailable = this.roomIsAvailable(feature?.externalId);

    if ((feature.enabled && !showViewOnlyColors) || (showViewOnlyColors && roomIsAvailable)) {
      return 'cilantro-40';
    }

    if (showViewOnlyColors && !roomIsAvailable) {
      return 'red-40';
    }

    return 'carbon-30';
  }

  getRoomHtml() {
    const { feature } = this.args;
    const labelColor = this.getLabelColor(this.args);

    return htmlSafe(`
      <div aria-hidden="true" data-test-feature-marker=${feature.type}>
        <div class="w-[30px] h-[30px]"></div>
        <div 
          class="feature-label absolute text-center text-${labelColor}" data-test-feature-label>
          ${feature.name || ''}
        </div>
      </div>
    `);
  }

  @action
  onInsert() {
    const { map } = this.args;
    this._appendArea(map);
  }

  @action
  onDestroy() {
    this.layerGroup.remove();
  }

  @action
  onUpdate() {
    const icon = new leaflet.DivIcon(this.iconOptions);

    if (this.isEditing && !this.args.feature.hasDirtyAttributes) {
      this.isEditing = false;
      this.area.editing.disable();
    }

    this.area.setStyle(this.colorOptions);
    this.marker.setIcon(icon);
  }

  @action
  updateIcon() {
    const icon = new leaflet.DivIcon(this.iconOptions);
    this.marker.setIcon(icon);
  }

  @action
  toggleEdit() {
    const { feature, selectedFeature, viewOnly, onDrag, onDragStart } = this.args;

    if (feature === selectedFeature && !viewOnly) {
      this.isEditing = true;
      this.area.editing.enable();

      const editingMakers = this.area.editing._verticesHandlers[0]._markerGroup.getLayers();
      editingMakers.forEach((marker) => {
        marker.on('dragstart', onDragStart);
        marker.on('drag', (e) => onDrag(e, this.area));
      });
    } else {
      this.isEditing = false;
      this.area.editing.disable();
    }

    const icon = new leaflet.DivIcon(this.iconOptions);
    this.marker.setIcon(icon);
    this.area.setStyle(this.colorOptions);
  }

  @action
  updateTooltip() {
    this.tooltip.options.opacity = isPresent(this.args.feature.name) ? 1 : 0;
    this.tooltip.setContent(this.args.feature.name);
  }

  @action
  onAreaDragStart({ target }) {
    this.area.editing.disable();
    target._path.classList.add('is-dragging');
  }

  @action
  onAreaDrag({ target }, marker) {
    const [coords] = target.getLatLngs();
    marker.setLatLng(new leaflet.Polygon(coords).getBounds().getCenter()).update();
  }

  @action
  onAreaDragEnd({ target }, feature) {
    target._path.classList.remove('is-dragging');
    const [updatedCoords] = target.getLatLngs();

    if (this.args.bounds.contains(updatedCoords)) {
      this.updateFeatureCoordinates(feature, updatedCoords);
    } else {
      const coords = feature.geometry.coordinates.map((coordsArray) =>
        toLatLngObject(coordsArray, this.args.mapDimensions),
      );

      target.setLatLngs(coords);
      this.area.editing.initialize(target);
      this.marker.setLatLng(new leaflet.Polygon(coords).getBounds().getCenter()).update();
    }

    this.args.onClick({ target });
  }

  @action
  onEditDrag({ target }, feature, marker) {
    const [updatedCoords] = target.getLatLngs();
    this.updateFeatureCoordinates(feature, updatedCoords);

    marker.setLatLng(new leaflet.Polygon(updatedCoords).getBounds().getCenter()).update();
  }

  @action
  updateFeatureCoordinates(feature, coordinates) {
    const coords = coordinates.map((latLng) => {
      const { xPos, yPos } = toCoords(latLng, this.args.mapDimensions);
      return [xPos, yPos];
    });
    const geometry = { ...feature.geometry };

    geometry.coordinates = coords;
    set(feature, 'geometry', geometry);
  }

  @action
  onDeleted() {
    const { feature } = this.args;

    if (feature.isDeleted) this.layerGroup.remove();
  }

  _appendArea(map) {
    const { feature, viewOnly, onClick, setIsOverArea, cancelDraw } = this.args;
    const coords = feature.geometry.coordinates.map((coordsArray) =>
      toLatLngObject(coordsArray, this.args.mapDimensions),
    );
    const area = new leaflet.Polygon(coords, {
      ...this.colorOptions,
      ...POLYGON_BASE_OPTIONS,
      draggable: !viewOnly && this.abilities.can('edit maps'),
    });

    const center = area.getBounds().getCenter();
    const icon = new leaflet.DivIcon(this.iconOptions);
    const marker = new leaflet.Marker(center, {
      icon,
      zIndexOffset: 10000,
    });
    const tooltip = new leaflet.Tooltip({
      opacity: isPresent(feature.name) ? 1 : 0,
      className: 'feature-tooltip',
    }).setContent(feature.name);

    marker.on('click', onClick).on('mousedown', cancelDraw).bindTooltip(tooltip);

    if (!viewOnly && this.abilities.can('edit maps')) {
      area
        .on('mouseover', () => setIsOverArea(true))
        .on('mouseout', () => setIsOverArea(false))
        .on('dragstart', this.onAreaDragStart)
        .on('drag', (e) => this.onAreaDrag(e, marker))
        .on('dragend', (e) => this.onAreaDragEnd(e, feature))
        .on('editdrag', (e) => this.onEditDrag(e, feature, marker))
        .on('edit', (e) => this.onEditDrag(e, feature, marker));
    }

    this.area = area;
    this.marker = marker;
    this.tooltip = tooltip;

    this.layerGroup = new leaflet.LayerGroup([area, marker], { isArea: true, feature }).addTo(map);

    this._registerPolygonTestSelectors(area, feature);
    this._registerMarkerTestSelectors(marker, feature);
  }

  _registerPolygonTestSelectors({ _path }, feature) {
    _path.dataset.testPolygon = true;
    _path.dataset.testPolygonFeatureType = feature.type;
    _path.dataset.testPolygonFeatureId = feature.id;
  }

  _registerMarkerTestSelectors({ _icon }, feature) {
    _icon.dataset.testFeatureType = feature.type;
    _icon.dataset.testFeatureId = feature.id;
  }
}
