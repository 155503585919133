import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type DraftModel from 'garaje/models/draft';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';

import type { MapsDraftsRouteModel } from './route';

const TABLE_COLUMNS = [
  { name: 'Name', sort: 'name', key: 'name', context: 'draft', label: 'name' },
  { name: 'Creation time', sort: 'creation-at', key: 'creationTime', context: 'this', label: 'created-at' },
  { name: 'Created by', sort: 'created-by', key: 'createdBy.name', context: 'this', label: 'created-by' },
  {
    name: 'Last edited by',
    sort: 'last-updated-by',
    key: 'lastUpdatedBy.name',
    context: 'this',
    label: 'last-updated-by',
  },
];

interface TableRow {
  name: string;
  sort: string;
  key: string;
  context: string;
  label: string;
}

export default class MapsDraftsController extends Controller {
  declare model: MapsDraftsRouteModel;

  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  @tracked drafts: DraftModel[] = [];

  get fieldOptions(): TableRow[] {
    return [...TABLE_COLUMNS];
  }

  get hasEmployee(): boolean {
    return !!this.currentAdmin.employee?.id;
  }

  get shouldDisableDraftButton(): boolean {
    return !this.hasEmployee;
  }

  @action
  trackAdminWithoutEmployee(): void {
    this.metrics.trackEvent('Admin without Employee attempted to access draft', { user_id: this.currentAdmin.id });
  }

  @action
  removeDraft(remove: DraftModel): void {
    this.drafts = this.drafts.filter((draft) => draft !== remove);
  }

  @action
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  sortDrafts(): void {}

  createDraftTask = task(
    { drop: true },
    waitFor(async () => {
      if (this.model.originalAreaMap) {
        const currentEmployeeId = this.currentAdmin.employee?.id;
        const newDraft = this.store.createRecord('draft', {
          createdBy: currentEmployeeId,
          originalAreaMap: this.model.originalAreaMap,
        });

        await newDraft.save();
        const draftAreaMapId = newDraft.belongsTo('draftAreaMap').id();
        const draftAreaMap = await this.store.findRecord('area-map', draftAreaMapId, { include: 'map-floors' });
        const firstMapFloorId = draftAreaMap.hasMany('mapFloors').ids()[0];

        if (firstMapFloorId) {
          await this.router.transitionTo('spaces.maps.drafts.area-map.show', draftAreaMapId, firstMapFloorId);
        } else {
          this.drafts = [...this.drafts, newDraft];
          await this.router.transitionTo('spaces.maps.drafts.area-map', draftAreaMapId);
        }
      }
    }),
  );
}
