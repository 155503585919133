import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class MapsEditShowRoute extends Route {
  @service store;
  @service state;
  @service metrics;
  @service transitionConfirm;
  @service router;
  @service abilities;

  beforeModel() {
    if (this.abilities.cannot('visit edit maps')) {
      this.router.transitionTo('spaces.maps.live');
    } else {
      this.metrics.trackEvent('Accessed edit map');
    }
  }

  async model({ floor_id }) {
    const { currentLocation } = this.state;
    const { areaMap } = this.modelFor('spaces.maps');

    const desksInLocation = this.store.query('desk', {
      filter: {
        'location-id': currentLocation.id,
        booked: true,
        permanentlyAssigned: true,
      },
      include: 'assignments',
    });

    return hash({
      areaMap,
      desksInLocation,
      mapFloor: this.store.findRecord('map-floor', floor_id),
      mapFeatures: this.store.query('map-feature', {
        filter: {
          'area-map': areaMap.id,
        },
      }),
    });
  }

  async afterModel({ mapFloor }) {
    const { areaMap } = this.modelFor('spaces.maps');
    const mapFloors = await areaMap.mapFloors;
    const hasFloorInLocation = mapFloors.any((floor) => floor.id == mapFloor.id);

    // added specifically for when you change locations
    if (!hasFloorInLocation) {
      this.router.transitionTo('spaces.maps.edit.show', mapFloors.firstObject.id);
    }
  }

  setupController(controller, { mapFeatures }) {
    super.setupController(...arguments);
    controller.mapFeatures = mapFeatures.toArray();
    controller.loadResoucesDataTask.perform();
  }

  resetController(controller, isExiting, transition) {
    if (isExiting && transition.targetName !== 'error') {
      controller.resetData();
    }
  }

  @routeEvent
  routeWillChange(transition) {
    const controller = this.controller;
    const dirtyFeatures = controller.mapFeatures.filterBy('hasDirtyAttributes');
    const deletableFeatures = controller.deletableFeatures;
    const desksWithDirtyAttributes = controller.desksWithDirtyAttributes;
    const dirtyNeighborhoods = controller.dirtyNeighborhoods;

    if (
      isEmpty(dirtyFeatures) &&
      isEmpty(deletableFeatures) &&
      isEmpty(desksWithDirtyAttributes) &&
      isEmpty(dirtyNeighborhoods) &&
      !controller.hasDirtyAssignments()
    ) {
      return;
    }

    this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        dirtyFeatures.invoke('rollbackAttributes');
        deletableFeatures.invoke('rollbackAttributes');
        desksWithDirtyAttributes.invoke('rollbackAttributes');
        dirtyNeighborhoods.invoke('rollbackAttributes');
        controller.clearDirtyAssignments();
        controller.selectedFeature = null;
        controller.mapFeatures = [...controller.mapFeatures, ...dirtyFeatures];
        controller.deletableFeatures = [];
      },
    });
  }
}
